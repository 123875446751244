import { FpHeader } from "./components";
import { HotglueConfig } from '@hotglue/widget';
import { Connections } from '@hotglue/widget';
import './connections-page.css'
import { Alert, Switch, TextField } from "@mui/material";
import BottomButton from "../components/kalk/bottom-button";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getConfig, Services } from "../model/services";
const sha256 = require('js-sha256');

const HOTGLUE_ENV = getConfig('REACT_APP_HOTGLUE_ENV');
const HOTGLUE_API_KEY = getConfig('REACT_APP_HOTGLUE_API_KEY');

const ConnectionsPage = () => {
	const initDone = useRef(false);
	const [consentSubmitted, setConsentSubmitted] = useState(false);
	const [formErrorEmail, setFormErrorEmail] = useState(false);
	const [email, setEmail] = useState("");

	const [error, setError] = useState(null);
  if (error) throw new Error(error);

	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			try {
				if (!initDone.current) {
				}
				initDone.current = true;
			} catch (e) {
				setError(e);
			}
		}
		
		init();
		
		return () => {};
  });

	function handleChange(event) {
		setEmail(event.target.value)
	}
	
	async function handleSubmit() {
		const regexEmail = /^\S+@\S+$/;
		const errorEmail = !email || (regexEmail.test(email) === false);
		setFormErrorEmail(errorEmail);

		if (!errorEmail) {
			await await Services.submitLeadConnection(email);
			setConsentSubmitted(true);
		}
	}

	return (
	  <HotglueConfig
		config={{
			apiKey: HOTGLUE_API_KEY,
			envId: HOTGLUE_ENV
		}}
		>

			<FpHeader/>

			<div className="connections">
				
				{!consentSubmitted && <div className="form-consent">
					<h1>Těší nás Váš zájem o financování od Flowpay.</h1>
					<div>Pokračováním souhlasíte se sdílením informací o Vašem podnikání se společností Flowpay. Jedině tak Vám Flowpay může nabídnout financování, které je opravdu na míru. Zásady ochrany sdílených údajů v podrobnostech <a target="_blank" href="https://my.flowpay.io/ochrana">zde</a>.</div>
					<div className="form-field">
						<TextField id="email" onChange={(e) => handleChange(e)} required label="Tvůj e-mail" variant="standard" className="lead-form-field"/>
						{formErrorEmail && <div className="form-error"><Alert severity="error">Zadejte prosím email</Alert></div>}
						<div className="form-button">
							<BottomButton inverse={false} text="Chci nabídku" onClick={handleSubmit}/>
						</div>
					</div>
				</div> }

				{consentSubmitted && <div className="hotglue">
					<h1>Připojit k účtu partnera</h1>
					<div className="connection-text">Po výběru budete přesměrováni na přihlášení u daného partnera.</div>
					<Connections tenant={sha256(email)} />
				</div>}

			</div>
		</HotglueConfig>
	);
};

export default ConnectionsPage;
	