import React from 'react'

import './splatka-item.css'
import {formatAmount, formatDate} from "../../model/core";

/**
 * Document : fp-onboarding - splatka-item.tsx
 * Created on : 9.2.2022, 17:49
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

export interface ISplatkaItem
{
  rootClassName?: string;
  key: number;
  poradiSplatky: number;
  datum: Date;
  castka: number;
}

const SplatkaItem = (props: ISplatkaItem) =>
{
  return (
      <div className={`financovani-item-container ${props.rootClassName} `}>
        <div className="financovani-item-row">
          <label className="inter600 financovani-item-datum">
            {formatDate(props.datum)}
          </label>
          <label className="inter600 financovani-item-poradiSplatky">
            - {props.poradiSplatky + ". splátka"}
          </label>
          <hr className="financovani-item-podtrzitko"/>
          <label className="inter800 financovani-item-castka">
            {formatAmount(props.castka)} Kč
          </label>
        </div>
        {/*<img alt="Line12175" className="financovani-item-podtrzitko" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nNDE5JyBoZWlnaHQ9JzEnIHZpZXdCb3g9JzAgMCA0MTkgMScgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz4KPGxpbmUgeTE9JzAuNScgeDI9JzQxOS4wMDEnIHkyPScwLjUnIHN0cm9rZT0nIzdENjI4OScgc3Ryb2tlLXdpZHRoPScwLjUnLz4KPC9zdmc+Cg==" />*/}
      </div>
  )
}

export default SplatkaItem
