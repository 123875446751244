import React, {Ref, useEffect, useRef, useState} from 'react'

import './castka-component.css'
import {formatAmount} from "../../model/core";
import {Alert, InputBase, Snackbar} from "@mui/material";

/**
 * Document : fp-onboarding - castka-component.tsx
 * Created on : 9.2.2022, 17:47
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

interface IFinancovaniComponent
{
  rootClassName: string;
  aktualniFinancovani: number;
  minFinanc: number;
  maxFinanc: number;
  updateAktFinanc: (noveFinancovani: number) => void;
}

const CastkaComponent = (props: IFinancovaniComponent) =>
{
  const inputRef = useRef<Ref<HTMLInputElement>>(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string>("");
  const [textFinancovani, setTextFinancovani] = useState<string>("");

  // Pomocná proměnná, abych si nezavíral alert onLostFocus
  const lostFocusUpdate = useRef<boolean>(false);

  /**
   * Event handler na ruční změnu textu v poli
   * @param event
   */
  const handle_lostFocus = (event: React.FocusEvent<HTMLInputElement>) =>
  {
    // Nastavuji event do paměti kvůli onClose Snacku
    lostFocusUpdate.current = true;
    doUpdateFinancovani(event.target.value);
  }

  /**
   * Handle tlačítka enter
   * @param event
   */
  const handle_enter = (event: any) =>
  {
    if (event.key === 'Enter')
    {
      doUpdateFinancovani(event.target.value);
    }
  }

  /**
   * Přepočítá financování
   * @param newText
   */
  const doUpdateFinancovani = (newText: string) =>
  {
    // Parsuji pouze číslo mě zajímá
    let noveCislo = parseInt(newText.replace(/\D/g,''));
    noveCislo = Math.ceil(noveCislo / 100) * 100;

    // Vejdu se do rozsahu ?
    if (noveCislo < props.minFinanc || props.maxFinanc < noveCislo)
    {
      setTextFinancovani(formatAmount(props.aktualniFinancovani) + " Kč");
      setAlertText("Lze zadat pouze částku v rozsahu financování!")
      setAlertOpen(true)
      return;
    }

    // Updatuju stav financování pokud se něco změnilo
    if (noveCislo !== props.aktualniFinancovani)
    {
      props.updateAktFinanc(noveCislo);
    }
  }

  /**
   * Zavře alert
   */
  const handle_CloseAlert = () =>
  {
    if (lostFocusUpdate.current)
    {
      lostFocusUpdate.current = false;
      return;
    }

    setAlertOpen(false);
  }

  /**
   * Effekt na nastavení aktuálního financování do testu
   */
  useEffect(() =>
  {
    setTextFinancovani(formatAmount(props.aktualniFinancovani) + " Kč");
  }, [props.aktualniFinancovani]);

  return (
      <>
        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handle_CloseAlert} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
          <Alert onClose={handle_CloseAlert} severity="error" sx={{ width: '100%' }}>
            {alertText}
          </Alert>
        </Snackbar>

        <div className={`${props.rootClassName}`}>
          <div className="button-financovani-container">
            <svg viewBox="0 0 1024 1024" className="button-financovani-minus" onClick={() => props.updateAktFinanc(props.aktualniFinancovani - 1000)}>
              <path d="M768 469.333h-512c-47.104 0-85.333 38.229-85.333 85.333s38.229 85.333 85.333 85.333h512c47.104 0 85.333-38.229 85.333-85.333s-38.229-85.333-85.333-85.333z"/>
            </svg>
            <InputBase value={textFinancovani} spellCheck={false} inputRef={inputRef}
                       onBlur={handle_lostFocus}
                       onKeyDown={handle_enter}
                       onDoubleClick={() => {
                         // @ts-ignore
                         inputRef.current.selectionStart = 0;
                         // @ts-ignore
                         inputRef.current.selectionEnd = inputRef.current.value.length;
                       }}
                       onChange={(event) => setTextFinancovani(event.target.value)}
                       inputProps={{
                         style: {
                           textAlign: 'center',
                           fontFamily: 'Inter',
                           fontWeight: 800,
                           fontSize: '26px',
                           color: '#FF4F90'
                         }
                       }}/>
            {/*<label contentEditable={true} spellCheck={false} onMouseLeave={() => handle_editAmount} className="nunito800 button-financovani-text"></label>*/}
            <svg viewBox="0 0 1024 1024" className="button-financovani-plus" onClick={() => props.updateAktFinanc(props.aktualniFinancovani + 1000)}>
              <path d="M768 426.667h-170.667v-170.667c0-47.104-38.229-85.333-85.333-85.333s-85.333 38.229-85.333 85.333l3.029 170.667h-173.696c-47.104 0-85.333 38.229-85.333 85.333s38.229 85.333 85.333 85.333l173.696-3.029-3.029 173.696c0 47.104 38.229 85.333 85.333 85.333s85.333-38.229 85.333-85.333v-173.696l170.667 3.029c47.104 0 85.333-38.229 85.333-85.333s-38.229-85.333-85.333-85.333z"/>
            </svg>
          </div>
        </div>
      </>
  )
}

export default CastkaComponent
