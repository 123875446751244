import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
// import HotglueConfig from '@hotglue/widget';
// import { getConfig } from './model/services';

// const HOTGLUE_ENV = getConfig('REACT_APP_HOTGLUE_ENV');
// const HOTGLUE_API_KEY = getConfig('REACT_APP_HOTGLUE_API_KEY');

ReactDOM.render(
  <React.StrictMode>
	  {/* <HotglueConfig
  	  config={{
    	  apiKey: HOTGLUE_API_KEY,
      	envId: HOTGLUE_ENV
    	}}
  	> */}
   		<App />
		{/* </HotglueConfig>, */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals();
