import { Widget } from '@typeform/embed-react'
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Services } from '../model/services';
import { ApplicationProcess, ProcessStep } from '../model/application-process.model';
import { useInterval } from '../model/core';
import { LoadingComponent, loadingComponent } from './components';
import { useNavigate } from "react-router-dom";
import HorizontalLinearStepper from './stepper';

const TYPEFORM_FORM_ID = process.env.REACT_APP_TYPEFORM_FORM_ID;

type ApplicationFormProps = {
	offerId: string;
	productId: string;
	process: ApplicationProcess;
}

const ApplicationForm = (props: ApplicationFormProps) => {
	const initDone = useRef(false);
	const [loading, setLoading] = useState(true);

	const {amount, postpone, crowdfunding, prolongation} = useParams();

	const [error, setError] = useState(null);
  if (error) throw new Error(error);

	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			try {
				if (!initDone.current) {

					if (!props.offerId || !props.productId || !props.process) throw 'InvalidParams';

					if (props.process.isStepCompleted(ProcessStep.Form)) {
						navigate(`/application/${props.process.application.id}/continue`);
					}

					// log lead interest
					// await Services.createLeadInterest(props.offerId);
					setLoading(false);
				}

				initDone.current = true;
			} catch (e) {
				// console.log(e);
				setError(e);
			}
		}
		
		init();
		
		return () => {};
  }, [props.process, props.productId, props.offerId]);

	const handleOnSubmitForm = (data: any) => {
		// console.log(data);
		navigate(`/application/process/offer/${props.offerId}/product/${props.productId}/response/${data.responseId}`);
	}

	if (loading) {
		return loadingComponent();
	} else {
		return (
			<Widget 			
				inlineOnMobile={true}
				enableSandbox={false} // if enableSandbox is true then the form is not submitted, i.e. no responseId is generated and no redirect fired
				onSubmit={handleOnSubmitForm}
				id={TYPEFORM_FORM_ID}
				// disableTracking={true}
				shareGaInstance={false}
				// disableAutoFocus={false}
				className="form"
				hidden={{
					offerId: props.process.offer.id,
					ico: props.process.offer.businessId ? props.process.offer.businessId : undefined,
					max_amount: props.process.offer.products[0].maxLoanAmount.toString(),
					required_amount: amount ? amount.toString() : undefined,
					required_postpone: postpone ? postpone.toString() : undefined,
					crowdfunding: crowdfunding ? 'true' : 'false',
					prolongation: prolongation ? 'true' : 'false',
					// partner_logo: '',
					// applicationId: application.id
				}}
			/>
		);
	}
}

const ApplicationFormProcess = (props) => {
	const initDone = useRef(false);
	const processed = useRef(false);

	const {responseId} = useParams();
	const [delay, setDelay] = useState(1000);

	const [error, setError] = useState(null);
  if (error) throw new Error(error);

	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			try {
				if (!initDone.current) {
					if (!responseId || !props.offerId || !props.productId) throw 'InvalidParams';

					initDone.current = true;
				}
			} catch (e) {
				setError(e);
			}
		}

		init();
		
		return () => {};
  }, [props.productId, props.offerId, responseId]);

	useInterval(async () => {
		const ready = await Services.checkFormDataReady(TYPEFORM_FORM_ID, responseId);
		if (ready && ready.data && !processed.current) {
			processed.current = true;
			const response = await Services.processFormResponse(props.offerId, props.productId, responseId);
			// console.log(response, 'response');
			const applicationId = response.data;
			if (applicationId) {
				navigate(`/application/${applicationId}/continue`);
			}
		}
  }, delay);

	useInterval(() => {
		setDelay(delay + 1000);
	}, delay);

	return loadingComponent();
}

const FormPage = () => {
	const initDone = useRef(false);

	const {step, offerId, productId} = useParams();
  const [loading, setLoading] = useState(true);
  const [process, setProcess] = useState<ApplicationProcess>();

	const [error, setError] = useState(null);
  if (error) throw new Error(error);

	useEffect(() => {
		const init = async () => {
			try {
				if (!initDone.current) {
					document.title = document.title.concat(' - Form');

					// console.log(offerId);
					if (!step || !offerId || !productId) throw 'InvalidParams';
					const process: ApplicationProcess = await Services.getApplicationProcess(offerId);
					if (!process || !process.offer) throw 'OfferNotFound';
					setProcess(process);

					console.log(process);
					console.log(process.isCompleted, 'isCompleted');
					console.log(process.isStepCompleted(ProcessStep.Form), 'step form');
					console.log(process.isStepCompleted(ProcessStep.Psd), 'step psd');
					console.log(process.isStepCompleted(ProcessStep.Kyc), 'step kyc');
					
					// kontrola ci je pozadovany existujuci produkt v ponuke
					// console.log(process.offer.products)
					const product = process.offer.products.filter(p => p.id === productId)[0];
					if (!product) throw 'OfferNotValid';

					setLoading(false);
					
					initDone.current = true;
				}
			} catch (e) {
				setError(e);
			}
		}
		init();
		
		return () => {};
  }, [offerId, productId, step]);

	if (loading) {
		return (
			<>
			{/* <HorizontalLinearStepper activeStep={ProcessStep.Form} showPsdStep={!process.isPsdValidPreliminary} showKycStep={!process.isKycValidPreliminary}/> */}
			<LoadingComponent/>
			</>
		)
	} else if (step === "start") {
		return (
			<>
			<HorizontalLinearStepper activeStep={ProcessStep.Form} showPsdStep={!process.isPsdValidPreliminary} showKycStep={!process.isKycValidPreliminary}/>
			<ApplicationForm offerId={offerId} productId={productId} process={process}/>
			</>
		)
	} else if (step === "process") {
		return (
			<>
			{/* <HorizontalLinearStepper activeStep={ProcessStep.Form} showPsdStep={!process.isPsdValidPreliminary} showKycStep={!process.isKycValidPreliminary}/> */}
			<ApplicationFormProcess offerId={offerId} productId={productId}/>
			</>
		)
	}	else if (step === "completed") {

	}
};

export default FormPage;
