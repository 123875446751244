import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import './app.css';
import NoPage from "./pages/no-page";
import FormPage from "./pages/form-page";
import PsdPage from "./pages/psd-page";
import DonePage from './pages/done-page';
import {Alert} from "@mui/material";
import * as Sentry from "@sentry/react";
import WorkflowPage from "./pages/workflow-page";
import KalkPage from "./pages/kalk-page";
import {FallbackComponent, GA} from "./pages/components";
import EntryPage from "./pages/entry-page";
import ConsentPage from "./pages/consent-page";
import KycFormPage from "./pages/kyc-page";
import AcceptTermsPage from "./pages/accept-terms-page";
import { Helmet, HelmetProvider } from "react-helmet-async";
import EntryPageStoryous from "./pages/entry-page-storyous";
import LeadPage from "./pages/lead-page";
import ConnectionsPage from "./pages/connections-page";

import { Auth0Provider } from '@auth0/auth0-react';
import { getConfig } from "./model/services";
import AbnLandingPage from "./pages/abn-landing-page";

const REACT_APP_AUTH0_DOMAIN = getConfig('REACT_APP_HOTGLUE_ENV');
const REACT_APP_AUTH0_CLIENT_ID = getConfig('REACT_APP_AUTH0_CLIENT_ID');
const REACT_APP_API_AUDIENCE = getConfig('REACT_APP_API_AUDIENCE');

const fpEnv = process.env.REACT_APP_FP_ENV;
const isProd = fpEnv && (fpEnv === "PROD");
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
const sentryEnabled = window.location.hostname !== 'localhost';
const sentryFeedbackEnabled = false;

Sentry.init({
  enabled: sentryEnabled,
	environment: fpEnv,
	// environment: process.env.FP_ENV,
	dsn: sentryDsn,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
	beforeSend(event, hint) {
		if (sentryFeedbackEnabled && event.exception) {
			Sentry.showReportDialog({ eventId: event.event_id });
		}
		return event;
	},
});
Sentry.setTag('fpEnv', fpEnv);
Sentry.setTag('fpApp', 'FE');

// function ErrorFallbackComponent({error, resetErrorBoundary}) {
//   return (
// 		<Alert severity="error">
// 			<AlertTitle>Something went wrong:</AlertTitle>
// 			{error.message}
// 		</Alert>
//   )
// }

function NonProdInfo() {
  return !isProd && (
		<Alert icon={false} severity="warning">{fpEnv}</Alert>
  )
}

// function errorHandler(error, info) {
//     // Do something with the error
//     // E.g. log to an error logging client here
// }

// Sentry.withErrorBoundary(FormPage, { fallback: <p>an error has occurred</p> });


// const FormPageWrapped = withErrorBoundary(FormPage, { FallbackComponent: ErrorFallbackComponent, onError: errorHandler });
// const FormProcessPageWrapped = withErrorBoundary(FormProcessPage, { FallbackComponent: ErrorFallbackComponent, onError: errorHandler });
// const FormCompletedPageWrapped = withErrorBoundary(FormCompletedPage, { FallbackComponent: ErrorFallbackComponent, onError: errorHandler });
// const PsdPageWrapped = withErrorBoundary(PsdPage, { FallbackComponent: ErrorFallbackComponent, onError: errorHandler });
// const KycPageWrapped = withErrorBoundary(KycPage, { FallbackComponent: ErrorFallbackComponent, onError: errorHandler });
// const DonePageWrapped = withErrorBoundary(DonePage, { FallbackComponent: ErrorFallbackComponent, onError: errorHandler });

const myFallback = (error) => <FallbackComponent error={error}/>;
// const dialogOptions: ReportDialogOptions = {
// 	// title?: string;
// 	// subtitle?: string;
// 	// subtitle2?: string;
// 	// labelName?: string;
// 	// labelEmail?: string;
// 	// labelComments?: string;
// 	// labelClose?: string;
// 	// labelSubmit?: string;
// 	// errorGeneric?: string;
// 	// errorFormEntry?: string;
// 	// successMessage?: string;
// };


const App = () => {
	document.title = 'Flowpay - Application'

	return (
		<HelmetProvider>
		<div className="app">
			{isProd && <GA/>}

			<NonProdInfo/>

			<Sentry.ErrorBoundary fallback={({ error }) => myFallback(error) }>
				<Router>
					<Routes>
						<Route path="/abn" element={<AbnLandingPage />} />

						<Route path="/leads/:leadId" element={<LeadPage />} />
						<Route path="/leads/:leadId/:step" element={<LeadPage />} />
						<Route path="/connections" element={<ConnectionsPage />} />

						{/* http://localhost:8888/entry/storyous?merchantId=&merchantSid=&businessNumber=&createdAt=&signature= */}
						<Route path="/entry/storyous" element={<EntryPageStoryous />} />
						<Route path="/calc/offer/:offerId" element={<KalkPage />} />

						<Route path="/entry/:partnerName" element={<EntryPage />} />
						<Route path="/consent/:partnerName/:merchantID" element={<ConsentPage />} />
						<Route path="/calc/:partnerName/:merchantID" element={<KalkPage />} />

						<Route path="/application/:step/offer/:offerId/product/:productId/amount/:amount" element={<FormPage />} />
						<Route path="/application/:step/offer/:offerId/product/:productId/amount/:amount/crowdfunding/:crowdfunding" element={<FormPage />} />
						<Route path="/application/:step/offer/:offerId/product/:productId/amount/:amount/postpone/:postpone" element={<FormPage />} />
						<Route path="/application/:step/offer/:offerId/product/:productId/amount/:amount/postpone/:postpone/prolongation/:prolongation" element={<FormPage />} />
						<Route path="/application/:step/offer/:offerId/product/:productId/amount/:amount/postpone/:postpone/crowdfunding/:crowdfunding" element={<FormPage />} />
						<Route path="/application/:step/offer/:offerId/product/:productId/amount/:amount/postpone/:postpone/prolongation/:prolongation/crowdfunding/:crowdfunding" element={<FormPage />} />
						<Route path="/application/:step/offer/:offerId/product/:productId/amount/:amount/prolongation/:prolongation" element={<FormPage />} />
						<Route path="/application/:step/offer/:offerId/product/:productId/amount/:amount/prolongation/:prolongation/crowdfunding/:crowdfunding" element={<FormPage />} />

						<Route path="/application/:step/offer/:offerId/product/:productId/" element={<FormPage />} />
						<Route path="/application/:step/offer/:offerId/product/:productId/response/:responseId" element={<FormPage />} />

						<Route path="/application/:applicationId/continue" element={<WorkflowPage />} />

						<Route path="/application/:applicationId/psd/:step" element={<PsdPage />} />

						<Route path="/application/:applicationId/kyc/:step" element={<KycFormPage />} />
						<Route path="/application/:applicationId/kyc/:step/person/:personId" element={<KycFormPage />} />
						<Route path="/application/:applicationId/kyc/:step/person/:personId/response/:responseId" element={<KycFormPage />} />

						<Route path="/application/:applicationId/done/:status" element={
								<Auth0Provider
									domain={REACT_APP_AUTH0_DOMAIN}
									clientId={REACT_APP_AUTH0_CLIENT_ID}
									audience={REACT_APP_API_AUDIENCE}
									redirectUri={window.location.origin}
									useRefreshTokens={true}
									useCookiesForTransactions={true}
									cacheLocation="localstorage">
	
									<DonePage />
								</Auth0Provider>
						} />

						<Route path="/application/:applicationId/accept-terms" element={<AcceptTermsPage />} />
						<Route path="/application/:applicationId/accept-terms/:step" element={<AcceptTermsPage />} />

						{/* <Route path="/test" element={<TestPage />} /> */}
						<Route path="*" element={<NoPage />} />
					</Routes>
				</Router>
			</Sentry.ErrorBoundary>
		</div>	
		</HelmetProvider>
	);
}

export default App;

/*
Requirements
- multilanguage
- multicurrency
- logging (Sentry)
- support for multiple PSD2 providers

Flow
- flow startuje ked klient klikne na button v PDF ziadosti
- input: offerId
- getOffer(offerId) - nacitanie ponuky z Airtable na zaklade offerId
- createApplication(offerId) - klient prejavil zaujem, vytvarame ziadost v Airtable tabulke Financing
	- Financing insert: Company_name, Status, Merchant_ICO_interest_form, Merchant_ID, Partner_name, name_surname, email_1, phone_1, signing_person, name_surname_2, email_2, phone_2, Account_number, loan_type, requested_amount, Offered_amount, Offered_fee_month, Offer_Valid_to, Offer_detail
	- Offer update: Status = Successful

- check ci uz ma financovanie
	- ak ano, predvyplnime data (ktore?)
	- check, ktore osoby maju platne KYC (1 rok)
	- check ci je ucet na cerpanie a ci je platne AML (1 rok)

- spustenie KYC flow pre kazdeho konatela s neplatnym KYC
- spustenie PSD2 flow ak ucet nema platne AML
- ak banka nie je podporovana PSD2 konektorom, spustenie flow na overenie uctu korunovym prevodom cez Signi


*/
