import { IPersonAttrs, StatusKyc } from "./customer.model";

export enum ApplicationStatus {
  New = 'New',
	Submitted = 'Submitted',
	Checking = 'Checking',
	SentForApproving = 'Sent for approving',
	WaitNewConditions = 'Wait new conditions',
	Approved = 'Approved',
	ContractSent = 'Contract sent',
	AmlChecked = 'AML checked',
	Signed = 'Signed',
	Disbursed = 'Disbursed',
	Overdue = 'Overdue',
	Collection = 'Collection',
	Paid = 'Paid',
	Refused = 'Client Refused',
	NotApproved = 'Not Approved',
	Expired = 'Expired'
}

export const UnderwritingStatus = [ApplicationStatus.Submitted, ApplicationStatus.Checking, ApplicationStatus.SentForApproving, ApplicationStatus.Approved, ApplicationStatus.ContractSent, ApplicationStatus.AmlChecked, ApplicationStatus.Signed, ApplicationStatus.WaitNewConditions];
export const ApplicationStatusActiveProducts = [ApplicationStatus.Disbursed, ApplicationStatus.Overdue, ApplicationStatus.Collection];

export enum StatusAml {
  Checked = 'Checked',
  Expired = 'Expired',
  NotChecked = 'Not checked',
	ManualCheck = 'Manual check'
}

export enum StatusPsd {
	Successful = 'Successful',
	Failed = 'Failed',
	Destroyed = 'Destroyed',
	Waiting = 'Waiting for connection',
	NotSupported = 'Not supported bank'
}

export enum ProviderPsd {
	Saltedge = 'Saltedge',
	Nordigen = 'Nordigen'
}

export interface IBank {
	code: string;
	name: string;
	bic: string;
	holderInfoSupported: boolean;
}
export interface IApplicationAttrs {
	id: string; // uuid
	offerId: string;
	partnerId: string;
	partnerCode: string;
	source: string;

	status: ApplicationStatus;
	statusAml: StatusAml;
	statusAmlDate: Date;
	statusPsd: StatusPsd;
	psdAccountHolderInfoSupported: boolean;
	psdProvider: ProviderPsd;
	psdCustomerId: string;
	psdConnectionId: string;
	psdAccounts: Array<any>;

	account: string; // "account/bank" in a single string
	amount: number; // requested loan amount

	// customer data
	businessId: string; // ICO
	businessName: string; // Company name
	externalId: string; // Merchant ID
	persons: Array<IPersonAttrs>;

	validTo: Date; // Offer_Valid_to
	created: Date;
	modified: Date;

	recId: string;
}

export class Application {
	private attrs: IApplicationAttrs;

	get id(): string {
		return this.attrs.id;
	}

	get recId(): string {
		return this.attrs.recId;
	}

	get statusAml(): StatusAml {
		return this.attrs.statusAml;
	}

	get statusPsd(): StatusPsd {
		return this.attrs.statusPsd;
	}

	get psdAccountHolderInfoSupported(): boolean {
		return this.attrs.psdAccountHolderInfoSupported;
	}

	get psdProvider(): ProviderPsd {
		return this.attrs.psdProvider;
	}

	get psdCustomerId(): string {
		return this.attrs.psdCustomerId;
	}

	get psdConnectionId(): string {
		return this.attrs.psdConnectionId;
	}

	get psdAccounts(): Array<any> {
		return this.attrs.psdAccounts;
	}

	get account(): string {
		return this.attrs.account;
	}

	get bank(): string {
		return this.attrs.account.substring(this.attrs.account.length-4);
	}

	get statusAmlDate(): Date {
		return this.attrs.statusAmlDate;
	}

	get created(): Date {
		// console.log(this.attrs.created, 'getter')
		return this.attrs.created;
	}

	get modified(): Date {
		return this.attrs.modified;
	}

	get validTo(): Date {
		return this.attrs.validTo;
	}

	get offerId(): string {
		return this.attrs.offerId;
	}

	get partnerId(): string {
		return this.attrs.partnerId;
	}

	get partnerCode(): string {
		return this.attrs.partnerCode;
	}

	get source(): string {
		return this.attrs.source;
	}

	get status(): ApplicationStatus	 {
		return this.attrs.status;
	}

	get amount(): number {
		return this.attrs.amount;
	}

	get externalId(): string {
		return this.attrs.externalId;
	}

	get businessId(): string {
		return this.attrs.businessId;
	}

	get businessName(): string {
		return this.attrs.businessName;
	}

	set businessName(value) {
		this.attrs.businessName = value;
	}

	get persons(): Array<IPersonAttrs> {
		return this.attrs.persons;
	}

	get statusKyc(): StatusKyc {
		// check if all persons have valid KYC
		return this.persons.reduce((result, person) => person.statusKyc !== StatusKyc.Valid ? StatusKyc.None : result, StatusKyc.Valid);
	}

	private constructor(attrs: IApplicationAttrs)
	{
		this.attrs = attrs;
	}

	public static create(attrs: IApplicationAttrs): Application
	{
		// EntityValidator.Mandatory('name', attrs.name);
		// EntityValidator.Mandatory('partnerId', attrs.status);
		// console.log(attrs.created, typeof attrs.created, 'create')
		return new Application(attrs);
	}

	public static checkPersonsMatch(p1: Array<IPersonAttrs>, p2: Array<IPersonAttrs>): boolean
	{
		if (p1.length !== p2.length) return false;

		const count = p1.length;

		// const p1sorted = p1.sort((a, b) => (a.email > b.email) ? 1 : ((b.email > a.email) ? -1 : 0));
		// const p2sorted = p2.sort((a, b) => (a.email > b.email) ? 1 : ((b.email > a.email) ? -1 : 0));

		const p1Emails = p1.reduce((result, p) => result.includes(p.email) ? result : result.concat([p.email]), []);
		const p2Emails = p2.reduce((result, p) => result.includes(p.email) ? result : result.concat([p.email]), []);
		const emailsIntersection = p1Emails.filter(it => p2Emails.includes(it));

		const p1Names = p1.reduce((result, p) => result.includes(p.name) ? result : result.concat([p.name]), []);
		const p2Names = p2.reduce((result, p) => result.includes(p.name) ? result : result.concat([p.name]), []);
		const namesIntersection = p1Names.filter(it => p2Names.includes(it));

		return emailsIntersection.length === count && namesIntersection.length === count;
	}
	
	public static matchPerson(name: string, email: string, persons: Array<IPersonAttrs>): string
	{
		const match = persons.filter(p => p.name === name && p.email === email);
		return match.length > 0 ? match[0].id : undefined;
	}

	public allPersonsHasValidKyc(): boolean {
		return this.persons.reduce((result: boolean, person) => result && [StatusKyc.Valid].includes(person.statusKyc), true)
	}
}
