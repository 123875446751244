import { Application, StatusPsd } from "./application.model";
import { StatusKyc } from "./customer.model";
import { Offer } from "./offer.model";

export interface IApplicationProcessAttrs {
	offer: Offer; 						// mandarory, application process always starts with an offer
	application: Application;	// optional, active application linked to the offer (if exists)
	isPsdValid: boolean;			// customer has valid PSD connection
	isKycValid: boolean;			// customer has valid KYC verification for all persons specified within the application
	isAmlValid: boolean;			// customer has valid AML verification = valid KYC + validated bank account
	isKycValidPreliminary: boolean;
	isPsdValidPreliminary: boolean;
	isUnderwriting: boolean;
	psdValidAppId: string;
	kycValidAppId: string;
}

export enum ProcessStep {
	Start = 'start',
	Calc = 'calc',
	Form = 'form',
	Psd = 'psd',
	Kyc = 'kyc',
	Done = 'done'
}

export class ApplicationProcess {
	private attrs: IApplicationProcessAttrs;

	get offer(): Offer {
		return this.attrs.offer;
	}

	get application(): Application {
		return this.attrs.application;
	}

	get isPsdValid(): boolean {
		return this.attrs.isPsdValid;
	}

	get isAmlValid(): boolean {
		return this.attrs.isAmlValid;
	}

	get isKycValid(): boolean {
		return this.attrs.isKycValid;
	}

	get isKycValidPreliminary(): boolean {
		return this.attrs.isKycValidPreliminary;
	}

	get isPsdValidPreliminary(): boolean {
		return this.attrs.isPsdValidPreliminary;
	}

	get isUnderwriting(): boolean {
		return this.attrs.isUnderwriting;
	}

	get psdValidAppId(): string {
		return this.attrs.psdValidAppId;
	}

	get kycValidAppId(): string {
		return this.attrs.kycValidAppId;
	}

	skipKycStep(): boolean {
		return (this.application.statusPsd === StatusPsd.NotSupported) || (this.application.psdAccountHolderInfoSupported === false);
	}

	isStepCompleted(step: ProcessStep): boolean {
		switch (step) {
			case ProcessStep.Form: 
				return this.application !== undefined;
				break;
			case ProcessStep.Kyc: 
				// KYC nerobime ak banka nepodporuje HolderInfo (pretoze KYC spravi Signi)
				// KYC step je completed ak kazda osoba ma bud KYC platne alebo poskytla udaje alebo dostala pozvanku
				return (this.application !== undefined) && (
					// this.isKycValid || 
					this.skipKycStep() || 
					this.application.persons.reduce((result: boolean, person) => result && [StatusKyc.Valid, StatusKyc.Rejected, StatusKyc.ManualReview, StatusKyc.DataProvided, StatusKyc.InviteSent].includes(person.statusKyc), true)
				);
				break;
			case ProcessStep.Psd: 
				// PSD nerobime ak banku nepodporujeme
				// return (this.application !== undefined) && (this.isPsdValid || (this.application.statusPsd === StatusPsd.NotSupported));
				// return (this.application !== undefined) && (this.application.statusPsd !== undefined);
				return (this.application !== undefined) && ([StatusPsd.Successful, StatusPsd.NotSupported].includes(this.application.statusPsd));
				break;
			default:
				throw new Error('Invalid step');
				break;
		}
	}

	get isCompleted(): boolean {
		return (this.application !== undefined) && this.isStepCompleted(ProcessStep.Form) && this.isStepCompleted(ProcessStep.Kyc) && this.isStepCompleted(ProcessStep.Psd);
	}

	get currentStep(): string {
		if (!this.isStepCompleted(ProcessStep.Form)) {
			return ProcessStep.Form;
		} else if (!this.isStepCompleted(ProcessStep.Psd)) {
				return ProcessStep.Psd;
		} else if (!this.isStepCompleted(ProcessStep.Kyc)) {
			return ProcessStep.Kyc;
		} else {
			return ProcessStep.Done;
		}
	}

	private constructor(attrs: IApplicationProcessAttrs)
	{
		this.attrs = attrs;
	}

	public static create(attrs: IApplicationProcessAttrs): ApplicationProcess
	{
		// EntityValidator.Mandatory('partnerId', attrs.partnerId);
		// EntityValidator.Mandatory('partnerId', attrs.status);

		return new ApplicationProcess(attrs);
	}
}
