import {ProductPeriod} from "./offer.model";

/**
 * Document : fp-onboarding - kalkulacka.model.ts
 * Created on : 14.2.2022, 22:15
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 14.2.2022: JOml - vytvoření souboru
 **/

export enum TypSplatnosti
{
  WEEK, MONTH
}

export interface IKalkProdukt
{
  typProduktu?: ProductPeriod;
  text: string;
  selected?: boolean;
  active?: boolean;
  intrestRate?: number;
  typSplatnosti?: TypSplatnosti;
  nasobekUroku?: number;
  pocetSplatek?: number;
  maxFinancovani?: number;
  delkaSplatnosti?: number;
}

export class KalkProdukt
{
  private readonly attrs: IKalkProdukt;

  constructor(attrs: IKalkProdukt)
  {
    this.attrs = attrs;
  }

  public static create(attrs: IKalkProdukt): KalkProdukt
  {
    return new KalkProdukt(attrs);
  }

  get key(): ProductPeriod
  {
    return this.attrs.typProduktu;
  }

  set key(value: ProductPeriod)
  {
    this.attrs.typProduktu = value;
  }

  get typProduktu(): ProductPeriod
  {
    return this.attrs.typProduktu;
  }

  set typProduktu(value: ProductPeriod)
  {
    this.attrs.typProduktu = value;
  }

  get text(): string
  {
    return this.attrs.text;
  }

  set text(value: string)
  {
    this.attrs.text = value;
  }

  get selected(): boolean
  {
    return this.attrs.selected;
  }

  set selected(value: boolean)
  {
    this.attrs.selected = value;
  }

  get active(): boolean
  {
    return this.attrs.active;
  }

  set active(value: boolean)
  {
    this.attrs.active = value;
  }

  get intrestRate()
  {
    return this.attrs.intrestRate;
  }

  set intrestRate(value)
  {
    this.attrs.intrestRate = value;
  }

  get typSplatnosti(): TypSplatnosti
  {
    return this.attrs.typSplatnosti;
  }

  set typSplatnosti(value: TypSplatnosti)
  {
    this.attrs.typSplatnosti = value;
  }

  get nasobekUroku(): number
  {
    return this.attrs.nasobekUroku;
  }

  set nasobekUroku(value: number)
  {
    this.attrs.nasobekUroku = value;
  }

  get pocetSplatek(): number
  {
    return this.attrs.pocetSplatek;
  }

  set pocetSplatek(value: number)
  {
    this.attrs.pocetSplatek = value;
  }

  get maxFinancovani()
  {
    return this.attrs.maxFinancovani;
  }

  set maxFinancovani(value)
  {
    this.attrs.maxFinancovani = value;
  }

  get delkaSplatnosti()
  {
    return this.attrs.delkaSplatnosti;
  }

  set delkaSplatnosti(value)
  {
    this.attrs.delkaSplatnosti = value;
  }
}