import { Alert, AlertTitle, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BottomButton from "../components/kalk/bottom-button";
import { Services } from "../model/services";
import { FpHeader } from "./components";
import './lead-page.css'

const LeadPage = () => {
	const initDone = useRef(false);
	const {step, leadId} = useParams();
	const [state, setState] = useState({email: "", regNum: "", phone: ""});
	const [formErrorEmail, setFormErrorEmail] = useState(false);
	const [formErrorRegNum, setFormErrorRegNum] = useState(false);
	const [formErrorPhone, setFormErrorPhone] = useState(false);

	const [error, setError] = useState(null);
  if (error) throw new Error(error);

	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			try {
				if (!initDone.current) {
					if (!leadId) throw 'InvalidParams';
				}
				initDone.current = true;
			} catch (e) {
				setError(e);
			}
		}
		
		init();
		
		return () => {};
  });

	async function handleSubmit() {
		if (step === 'done') return;

		const regexEmail = /^\S+@\S+$/;
		const errorEmail = !state.email || (regexEmail.test(state.email) === false);
		setFormErrorEmail(errorEmail);

		const regexNumeric = /^[0-9]+$/;
		const errorRegNum = !state.regNum || (regexNumeric.test(state.regNum) === false);
		setFormErrorRegNum(errorRegNum);

		const regexPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
		const errorPhone = !state.phone || (regexPhone.test(state.phone) === false);
		setFormErrorPhone(errorPhone);

		if (!errorEmail && !errorRegNum && !errorPhone) {
			await Services.submitLeadContact(leadId, state.email, state.regNum, state.phone);
			navigate(`/leads/${leadId}/done`);
		}
	}

	function handleChange(event) {
		const { id, value } = event.target;
    setState({ ...state, [id]: value });
  }

	return (
		<>
			<FpHeader/>
			<div className="content">
				<h1>Těší nás tvůj zájem o financování od Flowpay.</h1>
				<div className="lead-text">
					<p>Právě teď pro tebe nabídku nemáme, ale to se může změnit!</p>
					<p>Zanech na sebe kontakt a zkusíme něco vymyslet.</p>
				</div>
				<div className="lead-form">
				<span className="lead-form-field-wrapper">
						<TextField id="phone" onChange={(e) => handleChange(e)} required label="Tvůj telefon" variant="standard" className="lead-form-field"/>
					</span>
					<span className="lead-form-field-wrapper">
						<TextField id="email" onChange={(e) => handleChange(e)} required label="Tvůj e-mail" variant="standard" className="lead-form-field"/>
					</span>
					<span className="lead-form-field-wrapper">
						<TextField id="regNum" onChange={(e) => handleChange(e)} required label="IČO" variant="standard" className="lead-form-field"/>
					</span>
				</div>
				{(formErrorEmail || formErrorRegNum || formErrorPhone) && <div className="lead-form-error"><Alert severity="error">Zadejte prosím telefon, email a IČO</Alert></div>}
				<div>
					<BottomButton inverse={false} text="Chci nabídku" onClick={handleSubmit}/>
				</div>
				{(step === 'done') && <div className="lead-success"><Alert severity="success">Odesláno, děkujeme!</Alert></div>}
			</div>
		</>
	);
};

export default LeadPage;
