import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationProcess, ProcessStep } from "../model/application-process.model";
import { Application, ApplicationStatus } from "../model/application.model";
import { Services } from "../model/services";
import { LoadingComponent } from "./components";

const WorkflowPage = (props) => {
	const initDone = useRef(false);
	const [loading, setLoading] = useState(true);
	const {applicationId} = useParams();
  
	const [error, setError] = useState(null);
  if (error) {
		throw new Error(error);
	}

	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			try {
				if (!initDone.current) {

					if (!applicationId) throw 'InvalidParams';
					const application: Application = await Services.getApplication(applicationId);
					if (!application) throw 'InvalidParams';
					// console.log(application, '!1');
				  // setApplication(application)

					let process: ApplicationProcess;
					process = await Services.getApplicationProcess(application.offerId);

					if (!process || !process.offer) throw 'InvalidParams';

					console.log(process);
					console.log(process.isCompleted, 'isCompleted');
					console.log(process.isStepCompleted(ProcessStep.Form), 'step form');
					console.log(process.isStepCompleted(ProcessStep.Psd), 'step psd');
					console.log(process.isStepCompleted(ProcessStep.Kyc), 'step kyc');

					if (process.isCompleted && application.status !== ApplicationStatus.New) {
						throw `Underwriting-${application.status}`;
					} else if (process.isStepCompleted(ProcessStep.Form)) {
						if (!process.application || !process.application.id) throw 'InvalidApplication';

						if (!process.isStepCompleted(ProcessStep.Psd)) {
							navigate(`/application/${process.application.id}/psd/start`);
						} else if (!process.isStepCompleted(ProcessStep.Kyc)) {
							navigate(`/application/${process.application.id}/kyc/start`);
						} else if (process.isCompleted) {
							await Services.finishApplication(application.offerId, application.id);
							navigate(`/application/${process.application.id}/done/success`);
							// navigate(`/application/${process.application.id}/done/finish`);
							// throw `Underwriting-${application.status}`;
						} else {
							throw 'InvalidApplication';
						}
					}
					
					setLoading(false);
				}

				initDone.current = true;
			} catch (e) {
				setError(e);
			}
		}
		
		init();
		
		return () => {};
  }, [applicationId]);

	return (
		<>
		<LoadingComponent/>
		</>
	)
};

export default WorkflowPage;
