// import { EntityValidator } from "./entity";

export enum StatusKyc {
  None = 'None',
  DataProvided = 'DataProvided',
  Valid = 'Valid',
	InviteSent = 'InviteSent',
	Rejected = 'Rejected',
	ManualReview = 'ManualReview'
}

export interface IPersonAttrs {
	id: string;
	name: string;
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	pep?: boolean;
	statusKyc: StatusKyc;
}

// export partyId = (party: IPartyAttrs) => 
export interface IPartyAttrs {
	type: PartyType;
	
	name?: string;
	businessId?: string;

	firstName?: string;
	lastName?: string;
	dob?: Date;

	address?: string;
}

export enum PartyType {
	Individual = 'Individual',
	Organization = 'Organization'
}

// export interface ICompanyAttrs {
// 	externalId: string;
// 	businessId: string;
// 	name: string;
// }

// export interface ICustomerAttrs {
// 	id: string;
// 	businessId: string;
// 	name: string;
// 	externalId: string;
// 	status: string;
// }

export class Customer {
// 	private attrs: ICustomerAttrs;

// 	get id(): string {
// 		return this.attrs.id;
// 	}

// 	get businessId(): string {
// 		return this.attrs.businessId;
// 	}

// 	get name(): string {
// 		return this.attrs.name;
// 	}

// 	get externalId(): string {
// 		return this.attrs.externalId;
// 	}

// 	get status(): string {
// 		return this.attrs.status;
// 	}

// 	private constructor(attrs: ICustomerAttrs)
// 	{
// 		this.attrs = attrs;
// 	}

// 	public static create(attrs: ICustomerAttrs): Customer
// 	{
// 		EntityValidator.Mandatory('name', attrs.name);
// 		// EntityValidator.Mandatory('partnerId', attrs.status);

// 		return new Customer(attrs);
// 	}
}
