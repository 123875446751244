import {IOfferProduct, Offer, OfferStatus, ProductPeriod} from "./offer.model";

export interface OfferDto {
	id: string;
	partnerId: string;
	partnerRecId: string;
	externalId: string;
	externalId2: string;
	businessId: string;
	businessName: string;
  products: Array<IOfferProduct>;
	status: OfferStatus;
	expired: Date;
	sent: Date;
	created: Date;
	modified: Date;
	Interest_rate_offer_1week: number;
	Interest_rate_offer_2week: number;
	Interest_rate_offer_1month: number;
	Interest_rate_offer_3month: number;
	Interest_rate_offer_6month: number;
	Interest_rate_offer_12month: number;
	max_loan_amount_1week: number;
	max_loan_amount_2week: number;
	max_loan_amount_1month: number;
	max_loan_amount_3months: number;
	max_loan_amount_6months: number;
	max_loan_amount_12months: number;
	leadRecId?: string;
	Crowdfunding_offer: string;
}

export class OfferMapper
{
	public static mapProducts(raw: any): Array<IOfferProduct> {
		if (!raw) throw new Error('InvalidParams');

		let result = [];

		if (raw.max_loan_amount_1week && raw.Interest_rate_offer_1week && raw.Fee_1week) {
			result.push({
				id: ProductPeriod.W1,
				period: ProductPeriod.W1,
				maxLoanAmount: raw.max_loan_amount_1week,
				interestRate: raw.Interest_rate_offer_1week,
				fee: raw.Fee_1week
			});
		}
	
		if (raw.max_loan_amount_2week && raw.Interest_rate_offer_2week && raw.Fee_2week) {
			result.push({
				id: ProductPeriod.W2,
				period: ProductPeriod.W2,
				maxLoanAmount: raw.max_loan_amount_2week,
				interestRate: raw.Interest_rate_offer_2week,
				fee: raw.Fee_2week
			});
		}
		
		if (raw.max_loan_amount_1month && raw.Interest_rate_offer_1month && raw.Fee_1month) {
			result.push({
				id: ProductPeriod.M1,
				period: ProductPeriod.M1,
				maxLoanAmount: raw.max_loan_amount_1month,
				interestRate: raw.Interest_rate_offer_1month,
				fee: raw.Fee_1month
			});
		}

		if (raw.max_loan_amount_3months && raw.Interest_rate_offer_3month && raw.Fee_3months) {
			result.push({
				id: ProductPeriod.M3,
				period: ProductPeriod.M3,
				maxLoanAmount: raw.max_loan_amount_3months,
				interestRate: raw.Interest_rate_offer_3month,
				fee: raw.Fee_3months
			});
		}

		if (raw.max_loan_amount_6months && raw.Interest_rate_offer_6month && raw.Fee_6months) {
			result.push({
				id: ProductPeriod.M6,
				period: ProductPeriod.M6,
				maxLoanAmount: raw.max_loan_amount_6months,
				interestRate: raw.Interest_rate_offer_6month,
				fee: raw.Fee_6months
			});
		}

		if (raw.max_loan_amount_12months && raw.Interest_rate_offer_12month && raw.Fee_12months) {
			result.push({
				id: ProductPeriod.M12,
				period: ProductPeriod.M12,
				maxLoanAmount: raw.max_loan_amount_12months,
				interestRate: raw.Interest_rate_offer_12month,
				fee: raw.Fee_12months
			});
		}
		// console.log(result);
		return result;
	}
	
	public static toDomain(raw: any): Offer {
		if (!raw) throw new Error('InvalidParams');
		// console.log(raw)

		return Offer.create({
			id: raw.RecId,
			partnerId: raw.PartnerCode && raw.PartnerCode[0] ? raw.PartnerCode[0] : undefined,
			partnerRecId: raw.Partner_name && raw.Partner_name[0] ? raw.Partner_name[0] : undefined,
			externalId: raw.Merchant_ID,
			externalId2: raw.Merchant_ID2,
			businessId: raw.merchant_ICO,
			businessName: raw.Company_name,
			products: OfferMapper.mapProducts(raw),
			status: raw.Status,
			expired: new Date(raw.Offer_expired),
			sent: new Date(raw.Offer_sent),
			created: new Date(raw.Created),
			modified: new Date(raw.Last_modified),
			Interest_rate_offer_1week: raw.Interest_rate_offer_1week,
			Interest_rate_offer_2week: raw.Interest_rate_offer_2week,
			Interest_rate_offer_1month: raw.Interest_rate_offer_1month,
			Interest_rate_offer_3month: raw.Interest_rate_offer_3month,
			Interest_rate_offer_6month: raw.Interest_rate_offer_6month,
			Interest_rate_offer_12month: raw.Interest_rate_offer_12month,
			max_loan_amount_1week: raw.max_loan_amount_1week,
			max_loan_amount_2week: raw.max_loan_amount_2week,
			max_loan_amount_1month: raw.max_loan_amount_1month,
			max_loan_amount_3months: raw.max_loan_amount_3months,
			max_loan_amount_6months: raw.max_loan_amount_6months,
			max_loan_amount_12months: raw.max_loan_amount_12months,
			leadRecId: raw.Lead && raw.Lead[0] ? raw.Lead[0] : undefined,
			Crowdfunding_offer: raw.Crowdfunding_offer
		});
	}

	public static fromDto(dto: OfferDto): Offer {
		if (!dto) throw new Error('InvalidParams');
		
		return Offer.create({
			id: dto.id,
			partnerId: dto.partnerId,
			partnerRecId: dto.partnerRecId,
			externalId: dto.externalId,
			externalId2: dto.externalId2,
			businessId: dto.businessId,
			businessName: dto.businessName,
			products: dto.products,
			status: dto.status,
			expired: new Date(dto.expired),
			sent: new Date(dto.sent),
			created: new Date(dto.created),
			modified: new Date(dto.modified),
			Interest_rate_offer_1week: dto.Interest_rate_offer_1week,
			Interest_rate_offer_2week: dto.Interest_rate_offer_2week,
			Interest_rate_offer_1month: dto.Interest_rate_offer_1month,
			Interest_rate_offer_3month: dto.Interest_rate_offer_3month,
			Interest_rate_offer_6month: dto.Interest_rate_offer_6month,
			Interest_rate_offer_12month: dto.Interest_rate_offer_12month,
			max_loan_amount_1week: dto.max_loan_amount_1week,
			max_loan_amount_2week: dto.max_loan_amount_2week,
			max_loan_amount_1month: dto.max_loan_amount_1month,
			max_loan_amount_3months: dto.max_loan_amount_3months,
			max_loan_amount_6months: dto.max_loan_amount_6months,
			max_loan_amount_12months: dto.max_loan_amount_12months,
			leadRecId: dto.leadRecId,
			Crowdfunding_offer: dto.Crowdfunding_offer
		});
	}

	public static toDto(offer: Offer): OfferDto {
		if (!offer) throw new Error('InvalidParams');

		return {
			id: offer.id,
			partnerId: offer.partnerId,
			partnerRecId: offer.partnerRecId,
			externalId: offer.externalId,
			externalId2: offer.externalId2,
			businessId: offer.businessId,
			businessName: offer.businessName,
			products: offer.products,
			status: offer.status,
			expired: offer.expired,
			sent: offer.sent,
			created: offer.created,
			modified: offer.modified,
			Interest_rate_offer_1week: offer.Interest_rate_offer_1week,
			Interest_rate_offer_2week: offer.Interest_rate_offer_2week,
			Interest_rate_offer_1month: offer.Interest_rate_offer_1month,
			Interest_rate_offer_3month: offer.Interest_rate_offer_3month,
			Interest_rate_offer_6month: offer.Interest_rate_offer_6month,
			Interest_rate_offer_12month: offer.Interest_rate_offer_12month,
			max_loan_amount_1week: offer.max_loan_amount_1week,
			max_loan_amount_2week: offer.max_loan_amount_2week,
			max_loan_amount_1month: offer.max_loan_amount_1month,
			max_loan_amount_3months: offer.max_loan_amount_3months,
			max_loan_amount_6months: offer.max_loan_amount_6months,
			max_loan_amount_12months: offer.max_loan_amount_12months,
			leadRecId: offer.leadRecId,
			Crowdfunding_offer: offer.Crowdfunding_offer
		}
	}
}
