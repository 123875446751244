import Box from '@mui/material/Box';
import React from 'react';
import PhoneInput from 'react-phone-input-2'
import './mobile-phone.css'
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";

/**
 * Document : fp-onboarding - mobile-phone.tsx
 * Created on : 6.3.2022, 13:36
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 6.3.2022: JOml - vytvoření souboru
 **/

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      BoxInline: {
        flexDirection: "row",
        display: "flex",
        color: "black"
      }
    })
);

interface IMobilePhone
{
  rootClassName?: string;
  onChange: (phone: string) => void;
}

const MobilePhone = (props: IMobilePhone) =>
{
  const classes = useStyles();
  const [value, setValue] = React.useState<string>(null);

  /**
   * Zpracování změny telefonu
   */
  const handle_onChange = (phone: string) =>
  {
    setValue(phone);
    props.onChange(phone);
  };

  return (
      <Box className={`${classes.BoxInline} ${props.rootClassName} `} pt={5} pb={1}>
          <PhoneInput
              value={value}
              onChange={phone => handle_onChange(phone)}
              specialLabel={''}
              country={'cz'}
              inputStyle={{
                borderColor: "transparent",
                fontFamily: "Nunito",
                fontWeight: 700,
                fontSize: 20,
                borderRadius: 15
          }}/>
      </Box>
  );
};

export default MobilePhone