import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ApplicationProcess } from "../model/application-process.model";
import { Application, ApplicationStatus } from "../model/application.model";
import { Services } from "../model/services";
import { LoadingComponent, loadingComponent } from "./components";

const AcceptTermsPage = () => {
	const initDone = useRef(false);
	const {step, applicationId} = useParams();

	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	const [error, setError] = useState(null);
  if (error) throw new Error(error);
	
	useEffect(() => {
		const init = async () => {
			try {
				if (!initDone.current) {
					document.title = document.title.concat(' - Accept Terms');

					if (!applicationId) throw 'InvalidParams';				
					const application: Application = await Services.getApplication(applicationId);
					if (!application) throw 'InvalidParams';

					if (step === undefined) {
						await Services.acceptTerms(applicationId);
						window.location.replace(`/application/${applicationId}/accept-terms/done`)
						// navigate(`/application/${applicationId}/accept-terms/done`);
					} else if (step === 'done') {
						if (application.status !== ApplicationStatus.Approved) {
							throw 'Error';
						}
					}
					initDone.current = true;
					setLoading(false);
				}
			} catch (e) {
				setError(e);
			}
		}
		init();
		
		return () => {};
  }, [step, applicationId]);

	if (loading || step !== 'done') {
		return (
			<>
			<LoadingComponent/>
			</>
		)
	} else if (step === 'done') {
		return (
			<div className="content">
				<h1>Skvělá zpráva!</h1>			
				Akceptaci podmínek financování jsme přijali. Smlouvy jsou již na cestě k Vám.
			</div>
		);
	}
};

export default AcceptTermsPage;
