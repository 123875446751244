import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { OfferMapper } from "../model/offer.mapper";
import { getConfig, Services } from "../model/services";
import { loadingComponent } from "./components";
import { useNavigate } from "react-router-dom";

const EntryPageStoryous = (props) => {
	const initDone = useRef(false);
	const navigate = useNavigate();

	const [error, setError] = useState(null);
  if (error) throw new Error(error);

	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const init = async () => {
			try {
				if (!initDone.current) {
					const merchantId = searchParams.get('merchantId');
					const merchantSid = searchParams.get('merchantSid');
					const businessNumber = searchParams.get('businessNumber');
					const createdAt = searchParams.get('createdAt');
					const signature = searchParams.get('signature');
					if (!merchantId || !merchantSid || !businessNumber || !createdAt || !signature) throw 'InvalidParams';

					const HOST_MY_FLOWPAY = getConfig('REACT_APP_MY_FLOWPAY_URL');
					// const url = `${HOST_MY_FLOWPAY}/offer/Storyous/${merchantSid}/regNum/${businessNumber}/createdAt/${createdAt}/signature/${signature}`;
					const url = `${HOST_MY_FLOWPAY}/entry/Storyous?merchantId=${merchantSid}&regNum=${businessNumber}&createdAt=${createdAt}&signature=${signature}`;
					window.location.assign(url);

					// const offerId = await Services.storyousLead(merchantId, merchantSid, businessNumber, createdAt, signature);
					// navigate(`/calc/offer/${offerId}`);

					initDone.current = true;
				}
			} catch (e) {

				if (e.substring(0, 5) === 'Lead-') {
					const leadId = e.substring(5);
					window.location.assign(`/leads/${leadId}`);
				}

				setError(e);
			}
		}

		init();
		
		return () => {};
  }, []);

	return loadingComponent();
}

export default EntryPageStoryous;
