import React from 'react'

import './button-potvrzeni.css'

/**
 * Document : fp-onboarding - button-potvrzeni.tsx
 * Created on : 9.2.2022, 17:47
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

interface IButtonPotvrzeni
{
  rootClassName?: string;
  onClick: () => void;
  text: string;
}

const ButtonPotvrzeni = (props: IButtonPotvrzeni) =>
{
  return (
      <div onClick={() => props.onClick()} className={`button-potvrzeni-container ${props.rootClassName} `}>
        <label className="inter700 button-potvrzeni-text">{props.text}</label>
      </div>
  )
}

export default ButtonPotvrzeni
