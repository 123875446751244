/**
 * Document : fp-onboarding - info-button-small.tsx
 * Created on : 14.5.2022, 19:14
 * @author :  Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 14.5.2022: JOml - vytvoření souboru
 **/

import React from 'react'

import './info-button-small.css'
import {formatAmount} from "../../model/core";

/**
 * Document : fp-onboarding - info-button-big.tsx
 * Created on : 9.2.2022, 17:49
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

interface IInfoButtonSmall
{
  rootClassName?: string;
  crowdfunded?: boolean;
  puvodniCastka?: number;
  castka: number;
  info: string;
}

const InfoButtonSmall = (props: IInfoButtonSmall) =>
{
  return <div className={`info-button-main-small ${props.rootClassName}`}>
    <label className="inter700 info-button-info-small">
      {props.info}
    </label>

    <div className={`info-button-layout-small ${props.rootClassName}`}>
      <label style={props.crowdfunded ? {color: '#FF4F90', marginTop: '5px'} : null}
             className="inter800 info-button-castka-small">
        {formatAmount(props.castka)} Kč
      </label>

      {props.crowdfunded &&
          <label className="inter600 info-button-castka-small-puvodni">
            &nbsp;{formatAmount(props.puvodniCastka)} Kč&nbsp;
          </label>}
    </div>
  </div>
}

export default InfoButtonSmall
