import { Alert, AlertTitle } from "@mui/material";
import { FpHeader } from "./components";

const NoPage = () => {
  return (
		<>
			<FpHeader/>
			<div className="content">
				<h1>Je nám to líto, ale vyskytl se problém</h1>
					Požadovaná stránka neexistuje
			</div>
		</>
	);
};

export default NoPage;
