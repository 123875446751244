import './abn-landing-page.css';
import { FpHeader } from "./components";
import { HotglueConfig } from '@hotglue/widget';
import { Connections } from '@hotglue/widget';
import './connections-page.css'
import { Alert, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Switch, TextField, Tooltip } from "@mui/material";
import BottomButton from "../components/kalk/bottom-button";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getConfig, Services } from "../model/services";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {BrowserRouter as Router} from "react-router-dom";
import { useSearchParams } from "react-router-dom";


const sha256 = require('js-sha256');

const HOTGLUE_ENV = getConfig('REACT_APP_HOTGLUE_ENV');
const HOTGLUE_API_KEY = getConfig('REACT_APP_HOTGLUE_API_KEY');

const InlineTooltip = ({text}) => {
	return <Tooltip title={text}><IconButton size="small"><InfoIcon /></IconButton></Tooltip>;
}

const AbnLandingPage = () => {
	// const [lang, setLang] = useState<string>('nl');
	const initDone = useRef(false);
	const [consentSubmitted, setConsentSubmitted] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [formError, setFormError] = useState(false);
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [fullName, setFullName] = useState("");
	const [regNum, setRegNum] = useState("");

	const [error, setError] = useState(null);
  if (error) throw new Error(error);

	const navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const init = async () => {
			try {
				if (!initDone.current) {
				}
				initDone.current = true;

				// setLang(searchParams.get('lang'));

			} catch (e) {
				setError(e);
			}
		}
		
		init();
		
		return () => {};
  });

	function handleChangeEmail(event) {
		setEmail(event.target.value)
	}

	function handleChangePhone(event) {
		setPhone(event.target.value)
	}

	function handleChangeFullName(event) {
		setFullName(event.target.value)
	}

	function handleChangeRegNum(event) {
		setRegNum(event.target.value)
	}

	async function handleSubmit() {
		setFormSubmitted(true);

		const regexEmail = /^\S+@\S+$/;
		const errorEmail = !email || (regexEmail.test(email) === false);
		const errorPhone = false; //!phone;
		const errorFullName = !fullName;
		const errorRegNum = false; //!regNum;

		const validationError = errorEmail || errorPhone || errorFullName || errorRegNum;
		setFormError(validationError);

		if (!validationError) {
			await await Services.submitAbnConnection(email, phone, fullName, regNum);
			setConsentSubmitted(true);
		}
	}

	const styles = theme => ({
		listItemText:{
			fontSize:'0.7em',//Insert your required size
		}
	});

	const loc = (key) => {
		// setLang(searchParams.get('lang'));
		var lang = searchParams.get('lang'); if (!lang) { lang = 'nl' };
		switch (key) {
			case 'email':
				return lang == 'nl' ? 'E-mail adres' : 'Email address';
			case 'phone':
				return lang == 'nl' ? 'Telefoonnummer' : 'Phone number';
			case 'name':
				return lang == 'nl' ? 'Voor- en achternaam' : 'First and last name';
			case 'reg':
				return lang == 'nl' ? 'KVK nummer' : 'Chamber of Commerce number';
			case 'next':
				return lang == 'nl' ? 'Volgende stap' : 'Next step';
			case 'text':
				return lang == 'nl' ? 'Om jouw behoeften als klant beter te begrijpen maken we gebruik van verschillende types klantonderzoek zoals interviews, vragenlijsten en data-onderzoek. Bij het data-onderzoek kijken we in dit geval naar de data beschikbaar vanuit bijvoorbeeld je Shopify of WooCommerce account om te zien of we met deze data betere producten kunnen ontwikkelen en/of huidige producten scherper kunnen aanbieden.' : 'To better understand your needs as a customer, we use various types of customer research such as interviews, surveys, as well as data research. With data research, we examine the data available from your Shopify or WooCommerce account to see if we can develop better products and/or offer current products more effectively.';	
			case 'header':
				return lang == 'nl' ? 'Fijn dat je mee doet met dit data-onderzoek!! ' : 'Thank you for participating in this data research!';
		}

		return '';
	}

	const Text3 = () => {
		var lang = searchParams.get('lang'); if (!lang) { lang = 'nl' };
		return (lang == 'nl' ? 
			<>Als je hieronder doorgaat geef je toestemming dat FLOWPAY<InlineTooltip text="FLOWPAY means FLOWPAY B.V., registered at the Chamber of Commerce under number 87950731 and the Company Group and the Company Group; any of its parent, subsidiaries or other affiliates."/> jouw business data, inclusief evaluatie van deze data, deelt met ABN AMRO <InlineTooltip text="ABN AMRO means ABN AMRO Bank N.V., registered at the Chamber of Commerce under number 34334259 and the Company Group; any of its parent, subsidiaries or other affiliates including new10."/> en haar dochteronderneming New10. Hiermee doe je
			mee aan een onderzoek om de financiële dienstverlening van New10 voor E-commerce klanten te verbeteren. Je gaat hierdoor ook akkoord met de <a target="_blank" href="https://flowpay.io/en/privacy-policy">Privacy Policy</a>.</>
			:
			<>If you continue below, you give permission for FLOWPAY<InlineTooltip text="FLOWPAY means FLOWPAY B.V., registered at the Chamber of Commerce under number 87950731 and the Company Group and the Company Group; any of its parent, subsidiaries or other affiliates."/> to share your business data, including evaluation of your data, with ABN AMRO<InlineTooltip text="ABN AMRO means ABN AMRO Bank N.V., registered at the Chamber of Commerce under number 34334259 and the Company Group; any of its parent, subsidiaries or other affiliates including new10."/> and its subsidiary, New10. By doing this, you are participating in a study to improve New10&#39;s financial services for E-commerce customers. By proceeding, you also agree to the <a target="_blank" href="https://flowpay.io/en/privacy-policy">Privacy Policy</a>.</>
		);
	}

	return (
	  <HotglueConfig
		config={{
			apiKey: HOTGLUE_API_KEY,
			envId: HOTGLUE_ENV
		}}
		>

			<FpHeader withAbn={true}/>

			<div className="abn connections">
				
				{!consentSubmitted && <div className="form-consent" style={{paddingBottom: '100px'}}>
					<h1>{loc('header')}</h1>
					<div style={{paddingTop: '20px'}}>{loc('text')}</div>
					<div style={{paddingTop: '20px'}}><Text3/></div>

					{/* <div className="form-field">
						<TextField id="phone" onChange={(e) => handleChangePhone(e)} label={loc('phone')} variant="standard" className="lead-form-field"/>
					</div> */}
					<div className="form-field">
						<TextField id="fullName" onChange={(e) => handleChangeFullName(e)} required label={loc('name')} variant="standard" className="lead-form-field"/>
					</div>
					<div className="form-field">
						<TextField id="email" onChange={(e) => handleChangeEmail(e)} required label={loc('email')} variant="standard" className="lead-form-field"/>
					</div>
					{/* <div className="form-field">
						<TextField id="regNum" onChange={(e) => handleChangeRegNum(e)} required label={loc('reg')} variant="standard" className="lead-form-field"/>
					</div> */}
					{formSubmitted && formError && <div className="form-error"><Alert severity="error">Please provide all required information</Alert></div>}
					<div className="form-button">
							<BottomButton inverse={false} text={loc('next')} onClick={handleSubmit} style={{maxWidth: '420px'}}/>
					</div>
				</div> }

				{consentSubmitted && <div className="hotglue">
					<h1>Connect your platform!</h1>

					<List dense sx={{ fontFamily: "Nunito, sans-serif", pl: 0, pb: 5 }}>
						<ListSubheader sx={{ p: 0 }}>Instructions for connecting partner platforms</ListSubheader>
						<ListItem disablePadding>
							<ListItemButton sx={{p: 0}}>
								<ListItemIcon><ChevronRightIcon/></ListItemIcon>
								<ListItemText disableTypography sx={{ fontFamily: "Nunito, sans-serif", m: 0 }}>
									<a href="https://www.support.flowpay.io/shopify" target="_blank">Shopify Connection</a>
								</ListItemText>
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton sx={{p: 0}}>
								<ListItemIcon><ChevronRightIcon/></ListItemIcon>
								<ListItemText disableTypography sx={{ fontFamily: "Nunito, sans-serif", m: 0 }}>
									<a href="https://www.support.flowpay.io/woocommerce" target="_blank">Woocommerce Connection</a>
								</ListItemText>
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton sx={{p: 0}}>
								<ListItemIcon><ChevronRightIcon/></ListItemIcon>
								<ListItemText disableTypography sx={{ fontFamily: "Nunito, sans-serif", m: 0 }}>
									<a href="https://www.support.flowpay.io/prestashop" target="_blank">PrestaShop Connection</a>
								</ListItemText>
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton sx={{p: 0}}>
								<ListItemIcon><ChevronRightIcon/></ListItemIcon>
								<ListItemText disableTypography sx={{ fontFamily: "Nunito, sans-serif", m: 0 }}>
									<a href="https://www.support.flowpay.io/magentov1" target="_blank">Magento V1 Connection</a>
								</ListItemText>
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton sx={{p: 0}}>
								<ListItemIcon><ChevronRightIcon/></ListItemIcon>
								<ListItemText disableTypography sx={{ fontFamily: "Nunito, sans-serif", m: 0 }}>
									<a href="https://www.support.flowpay.io/magento" target="_blank">Magento Connection</a>
								</ListItemText>
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton sx={{p: 0}}>
								<ListItemIcon><ChevronRightIcon/></ListItemIcon>
								<ListItemText disableTypography sx={{ fontFamily: "Nunito, sans-serif", m: 0 }}>
									<a href="https://www.support.flowpay.io/dotykacka" target="_blank">Dotykačka Connection</a>
								</ListItemText>
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton sx={{p: 0}}>
								<ListItemIcon><ChevronRightIcon/></ListItemIcon>
								<ListItemText disableTypography sx={{ fontFamily: "Nunito, sans-serif", m: 0 }}>
									<a href="https://www.support.flowpay.io/googleanalytics" target="_blank">Google Analytics Connection</a>
								</ListItemText>
							</ListItemButton>
						</ListItem>
					</List>

					<Connections tenant={sha256(email)} multipleSources={true}/>
				</div>}

			</div>
		</HotglueConfig>
	);
};

export default AbnLandingPage;
