import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Application, ProviderPsd, StatusPsd } from '../model/application.model';
import { Services } from '../model/services';
import { ApplicationDto } from '../model/application.mapper';
import { ApplicationProcess, ProcessStep } from '../model/application-process.model';
import { Widget } from '@typeform/embed-react'
import { useInterval, useQuery } from '../model/core';
import { LoadingComponent, loadingComponent } from './components';
import HorizontalLinearStepper from './stepper';

// const HOST_FE = window.location.origin;
const TYPEFORM_CONSENT_ID = process.env.REACT_APP_TYPEFORM_CONSENT_ID;
const TYPEFORM_CONSENT_ID_NORDIGEN = process.env.REACT_APP_TYPEFORM_CONSENT_ID_NORDIGEN

const PsdConsentComponent = (props) => {
	const navigate = useNavigate();
	const initDone = useRef(false);
  const [loading, setLoading] = useState(true);
	const [config, setConfig] = useState(null);
	const [error, setError] = useState(null);
  if (error) throw new Error(error);

	const handleOnSubmitConsent = (data: any) => {
		// console.log(data.responseId, 'form submit')
		// TODO nacitat response a skontrolovat suhlas
		navigate(`/application/${props.applicationId}/psd/process`);
	}
	
	useEffect(() => {
		const init = async () => {
			try {
				if (!initDone.current) {
					const config = await Services.getConfig();
					setConfig(config);

					initDone.current = true;
					setLoading(false);
				}
			} catch (e) {
				setError(e);
			}
		}
		
		init();
		
		return () => {};
  }, []);

	if (props.applicationId && !loading) {
		return (
			<Widget 
				inlineOnMobile={true}
				enableSandbox={false} // if enableSandbox is true then the form is not submitted, i.e. no responseId is generated and no redirect fired
				onSubmit={handleOnSubmitConsent}
				id={config['PSD2_PROVIDER'] === 'Nordigen' ? TYPEFORM_CONSENT_ID_NORDIGEN : TYPEFORM_CONSENT_ID}
				// disableTracking={true}
				shareGaInstance={false}
				// disableAutoFocus={false}
				className="form"
				hidden={{
					applicationId: props.applicationId
				}}
			/>
		);
	} else {
		return loadingComponent()	
	}
}

const PsdConnectionComponent = (props) => {
	const initDone = useRef(false);
	const [config, setConfig] = useState(null);

	const [error, setError] = useState(null);
  if (error) throw new Error(error);

	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			try {
				if (!initDone.current) {
					if (!props.application) throw 'InvalidParams';

					const process: ApplicationProcess = await Services.getApplicationProcess(props.application.offerId);
					// console.log(process)
					if (!process || process.isStepCompleted(ProcessStep.Psd)) throw 'InvalidParams';

					const config = await Services.getConfig();
					setConfig(config);

					let psdSessionUrl;
					if (config['PSD2_PROVIDER'] === 'Nordigen') {
						psdSessionUrl = await Services.initNordigenSession(props.application.id, props.application.bank);
					} else {
						psdSessionUrl = await Services.initSaltEdgeSession(props.application.id, props.application.persons[0].email, props.application.bank);
					}
					if (psdSessionUrl) {
						window.location.assign(psdSessionUrl);
					} else {						
						navigate(`/application/${props.application.id}/continue`);
					}

					initDone.current = true;
				}
			} catch (error) {
				setError(error);
			}
		}
		
		init();		
		return () => {};
  }, [props.application]);

	return loadingComponent();
}

const PsdCompletedComponent = (props) => {
	const initDone = useRef(false);

	const query = useQuery();
	const [delay, setDelay] = useState(1000);

	const [error, setError] = useState(null);
  if (error) throw new Error(error);

	const navigate = useNavigate();

	useEffect(() => {
		// console.log(1)
		const init = async () => {
			try {
				if (!initDone.current) {
					if (!props.application || !props.application.id) throw 'InvalidParams';

					// Saltedge: ?shared=true
					// Nordigen: ?ref=...&error=...&details=... (reference)
					const shared = query.get("shared");
					const error = query.get("error");
					const ref = query.get("ref");
					const details = query.get("details");

					console.log(props.application.psdProvider, 'psdProvider')
					console.log(ref, 'ref')
					console.log(error, 'error')
					console.log(details, 'details')

					if (((props.application.psdProvider === ProviderPsd.Nordigen) && error) ||
						  ((props.application.psdProvider !== ProviderPsd.Nordigen) && !shared))
				  {
						console.log('restart')
						if (error) {
							reportError({error: 'NordigenError', info: {error, details}});
						}
						navigate(`/application/${props.application.id}/psd/start`);
					}	

					initDone.current = true;
				}
			} catch (e) {
				setError(e);
			}
		}
		
		init();
		
		return () => {};
  }, [props.application, query]);

	useInterval(async () => {
		if (delay > 10000) {
			setError('Error creating PSD connection');
		} else {

			// for Nordigen we have to change the connection state
			// for Saltedge there is a callback request executed by Saltedge behind the scenes
			if (props.application.psdProvider === ProviderPsd.Nordigen) {
				const connectionStatus = await Services.setPsdConnection(props.application.id, props.application.psdCustomerId)
				console.log(connectionStatus);
			}

			const application: ApplicationDto = await Services.getApplication(props.application.id);
			if (!application) throw 'InvalidParams';

			switch (application.statusPsd) {
				case StatusPsd.Successful:
				case StatusPsd.NotSupported:
						// window.location.replace(`${HOST_FE}/application/${props.applicationId}/kyc/start`);
						// console.log(application)
						if (application.psdAccountHolderInfoSupported) {
							navigate(`/application/${props.application.id}/continue`);
						} else {
							navigate(`/application/${props.application.id}/done/success`);
						}
					break;
				case StatusPsd.Failed:
				case StatusPsd.Destroyed:
					navigate(`/application/${props.application.id}/psd/start`);
					break;
				default:
					break;
			}
		}
  }, delay);

	useInterval(() => {
		setDelay(delay + 1000);
	}, delay);

	return loadingComponent();
}

const PsdPage = () => {
	const initDone = useRef(false);

	const {applicationId, step} = useParams();
  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState<Application>();
  const [process, setProcess] = useState<ApplicationProcess>();

	const [error, setError] = useState(null);
  if (error) throw new Error(error);

	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			try {
				if (!initDone.current) {
					document.title = document.title.concat(' - PSD2');

					initDone.current = true;

					const application: Application = await Services.getApplication(applicationId);
					// console.log(application)
					setApplication(application);
					if (!application) throw 'InvalidParams';
					if (!step) throw 'InvalidParams';

					const process: ApplicationProcess = await Services.getApplicationProcess(application.offerId);
					if (!process || !process.offer) throw 'OfferNotFound';
					setProcess(process);
					
					// console.log(process);
					// console.log(process.isStepCompleted(ProcessStep.Kyc))	

					if (step === "start") {
						// skip step if finished
						// do not skip if second person is opening to the form screen directly
						if (process.isStepCompleted(ProcessStep.Psd)) {
							navigate(`/application/${applicationId}/continue`);
						}

						// if returns true -> continue with PSD connection
						const isPsdSupportedAndNotConnected = await Services.checkPsdSupport(application);
						// console.log(isPsdSupported);
						if (!isPsdSupportedAndNotConnected) {
							navigate(`/application/${applicationId}/continue`);
						}
					}

					setLoading(false);
				}
			} catch (e) {
				setError(e);
			}
		}
		init();
		
		return () => {};
  }, [applicationId, step]);

	if (loading) {
		return (
			<>
			<LoadingComponent/>
			</>
		)
	} else if (step === "start") {
		return (
			<>
			<HorizontalLinearStepper activeStep={ProcessStep.Psd} showPsdStep={true} showKycStep={true}/>
			{/* <HorizontalLinearStepper activeStep={ProcessStep.Psd} showPsdStep={!process.isStepCompleted(ProcessStep.Psd)} showKycStep={!process.isStepCompleted(ProcessStep.Kyc)}/> */}
			<PsdConsentComponent applicationId={applicationId}/>
			</>
		)
	} else if (step === "process") {
		return (
			<>
			<PsdConnectionComponent application={application}/>
			</>
		)
	}	else if (step === "completed") {
		return (
			<>
			<PsdCompletedComponent application={application}/>
			</>
		)
	}
};

export default PsdPage;
