import React from 'react'

import './prolong-button.css'

/**
 * Document : fp-onboarding - button-potvrzeni.tsx
 * Created on : 9.2.2022, 17:47
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

interface IProlongButton
{
  rootClassName?: string;
  onClick: (oldSelected: boolean) => void;
  text: string;
  isSelected: boolean;
}

const ProlongButton = (props: IProlongButton) =>
{
  // Výběr třídy podle toho jestli je toggle zapnutý nebo ne
  let containerClass = "";
  let textClass = "";
  if (props.isSelected)
  {
    containerClass = "toggle-button-container-true";
    textClass = "toggle-button-text-true";
  }
  else
  {
    containerClass = "toggle-button-container-false";
    textClass = "toggle-button-text-false";
  }

  // Render tlačítka
  return (
      <div onClick={() => props.onClick(props.isSelected)} className={`toggle-button-container ${props.rootClassName} ${containerClass}`}>
        <label className={`inter600 toggle-button-text ${textClass}`}>{props.text}</label>
      </div>
  )
}

export default ProlongButton
