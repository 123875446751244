import axios from "axios";
import {ApplicationMapper} from "./application.mapper";
import {Application} from "./application.model";
import {ApplicationProcessMapper} from "./application-process.mapper";
import {ApplicationProcess} from "./application-process.model";
import { OfferDto, OfferMapper } from "./offer.mapper";
import { Offer } from "./offer.model";
const sha256 = require('js-sha256');

const HOST_FE = window.location.origin;
const HOST_BE = window.location.origin + process.env.REACT_APP_FP_API;

const fpEnv = process.env.REACT_APP_FP_ENV;
export const isDev = fpEnv && (fpEnv === "DEV");
export const isTest = fpEnv && (fpEnv === "TEST");
export const isProd = fpEnv && (fpEnv === "PROD");

// export const isDev = false;
// export const isTest = false;
// export const isProd = true;

export function getConfig(param) {	
	// console.log(param, isProd)

	if ((param == 'HOST') || (param == 'HOST_BE')) {
		return process.env.NETLIFY_LOCAL ? process.env.URL : HOST_BE;
	}

	if (isProd) {
		const prodParam = `${param}_PROD`;
		return process.env[prodParam] ? process.env[prodParam] : process.env[param];
	}
	
	if (isTest) {
		const testParam = `${param}_TEST`;
		return process.env[testParam] ? process.env[testParam] : process.env[param];
	}

	return process.env[param];
}
export class Services {

	static async getBanks()
	{
	}

	static async submitLeadConnection(email)
	{
		try {
			const result = await axios.post(`${HOST_BE}/crm`, {
				op: 'submitLeadConnection', 
				email: email
			});

			return result.data;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async createLead(partnerName, merchantId)
	{
		try {
			const result = await axios.post(`${HOST_BE}/crm`, {
				op: 'createLead', 
				partnerName: partnerName,
				merchantId: merchantId
			});

			return result.data;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async createLeadOpportunity(offerId)
	{
		try {
			const result = await axios.post(`${HOST_BE}/crm`, {
				op: 'createLeadOpportunity',
				offerId: offerId,
			});

			return result.data;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async createLeadInterest(offerId, product?)
	{
		console.log(product);
		try {
			const result = await axios.post(`${HOST_BE}/crm`, {
				op: 'createLeadInterest',
				offerId: offerId,
				product_type: !product ? null : product.type,
				product_amount: !product ? null : product.amount,
				product_postponedPeriods: !product ? null : product.postponedPeriods,
				product_prolonged: !product ? null : product.prolonged,
				product_crowdfunded: !product ? null : product.crowdfunded
			});

			return result.data;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async storyousLead(merchantId, merchantSid, businessNumber, createdAt, signature)
	{
		try {
			const result = await axios.post(`${HOST_BE}/crm`, {
				op: 'storyousLead', 
				merchantId: merchantId,
				merchantSid: merchantSid,
				businessNumber: businessNumber,
				createdAt: createdAt,
				signature: signature
			});

			if ((result.status === 200) && result.data && result.data.offerId) {
				return result.data.offerId;
			} else {
				throw 'OfferNotFound';
			}
		} catch (error) {
			console.log(error)
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async getOfferByMerchantId(partnerId: string, merchantId: string): Promise<Offer>
	{
		try {
			const result = await axios.get(`${HOST_BE}/crm`, {params: {
				op: 'getOfferByMerchantId', 
				merchantId: merchantId,
				partnerId: partnerId
			}});

			return OfferMapper.fromDto(result.data);
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async getOffer(offerId: string): Promise<Offer>
	{
		try {
			const result = await axios.get(`${HOST_BE}/crm`, {params: {
				op: 'getOffer', 
				offerId: offerId
			}});

			return OfferMapper.fromDto(result.data);
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async getApplicationProcess(id: string): Promise<ApplicationProcess> {
		try {
			const result = await axios.get(`${HOST_BE}/crm`, {params: {
				op: 'getApplicationProcess', 
				offerId: id
			}});
			// console.log(result)
			return ApplicationProcessMapper.fromDto(result.data);
		} catch (error) {
			// console.log(error.response, 'get')
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}
	
	static async getApplication(id: string): Promise<Application>
	{
		try {
			const result = await axios.get(`${HOST_BE}/crm`, {params: {
				op: 'getApplication', 
				id: id
			}});

			return ApplicationMapper.fromDto(result.data);
		} catch (error) {
			console.log(error.response, 'get')
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async checkFormDataReady(formId: string, responseId: string): Promise<any> {
		try {
			const result = await axios.post(`${HOST_BE}/crm?op=checkFormDataReady`, {
				op: 'checkFormDataReady', 
				formId: formId,
				responseId: responseId
			});
			return result;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async finishApplication(offerId: string, applicationId: string)
	{
		try {
			const result = await axios.post(`${HOST_BE}/crm?op=finishApplication`, {
				op: 'finishApplication', 
				offerId: offerId,
				applicationId: applicationId
			});
			return result;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async processFormResponse(offerId: string, productId: string, responseId: string): Promise<any> {
		try {
			const result = await axios.post(`${HOST_BE}/crm?op=processFormResponse`, {
				op: 'processFormResponse', 
				offerId: offerId,
				productId: productId,
				responseId: responseId
			});
			return result;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async processKycFormResponse(applicationId: string, personId: string, responseId: string): Promise<any> {
		try {
			const result = await axios.post(`${HOST_BE}/kyc?op=processKycFormResponse`, {
				op: 'processKycFormResponse', 
				applicationId: applicationId,
				personId: personId,
				responseId: responseId
			});
			return result;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async startKycProcess(applicationId: string, personId: string): Promise<any> {
		try {
			const result = await axios.post(`${HOST_BE}/kyc?op=startKycProcess`, {
				op: 'startKycProcess', 
				applicationId: applicationId,
				personId: personId,
				inviteUrlTemplate: `${HOST_FE}/application/${applicationId}/kyc/form/person/:personId`
			});
			return result.data;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async initSaltEdgeSession(applicationId: string, email: string, bank: string): Promise<any> {
		try {
			const result = await axios.post(`${HOST_BE}/psd?op=requestPsdConnection`, {
				"op": "requestPsdConnection",
				"applicationId": applicationId,
				"email": email,
				"bank": bank,
				"returnUrl": `${HOST_FE}/application/${applicationId}/psd/completed`
			});
			return result.data.unsupported ? false : result.data.redirect_url;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async initNordigenSession(applicationId: string, bank: string): Promise<any> {
		try {
			const result = await axios.post(`${HOST_BE}/psd-nordigen?op=requestPsdConnection`, {
				"op": "requestPsdConnection",
				"applicationId": applicationId,
				"bank": bank
			});
			console.log(result)
			return result.data.unsupported ? false : result.data.redirect_url;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	/**
	 * Service pro získání posledního záznamu z tabulky offer podle MerchantID
	 * @param merchantID
	 * @param partnerName
	 */
	static async getOfferDto(merchantID: string, partnerName: string): Promise<any>
	{
		try {
			const result = await axios.get(`${HOST_BE}/kalk`, {
				params: {
					op: 'getOffer',
					merchantID: merchantID,
					partnerName: partnerName
				}
			});
			return result;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	/**
	 * Získání Merchant ID podle telefoního čísla
	 * @param phone
	 * @param partnerName
	 */
	static async getMerchantID(phone: string, partnerName: string): Promise<any>
	{
		try {
			const result = await axios.get(`${HOST_BE}/kalk`, {
				params: {
					op: 'getMerchantID',
					phone: phone,
					partnerName: partnerName
				}
			});
			return result;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async checkPsdSupport(application: Application): Promise<any>
	{
		try {
			const result = await axios.post(`${HOST_BE}/psd-nordigen?op=checkPsdSupport`, {
				"op": "checkPsdSupport",
				"applicationId": application.id,
				"offerId": application.offerId
			});
			return result.data;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async setPsdConnection(applicationId: string, reference: string): Promise<any>
	{
		try {
			const result = await axios.post(`${HOST_BE}/psd-nordigen?op=setPsdConnection`, {
				"op": "setPsdConnection",
				"applicationId": applicationId,
				"reference": reference
			});
			return result.data;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async getConfig()
	{
		try {
			const result = await axios.post(`${HOST_BE}/crm?op=getConfig`, {
				"op": "getConfig"
			});
			return result.data;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}		
	}

	static async acceptTerms(applicationId)
	{
		try {
			const result = await axios.post(`${HOST_BE}/underwriting?op=acceptTerms`, {
				"op": "acceptTerms",
				"applicationId": applicationId
			});
			return result.data;			
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async submitLeadContact(leadId, email, regNum, phone)
	{
		try {
			const result = await axios.post(`${HOST_BE}/crm?op=submitLeadContact`, {
				"op": "submitLeadContact",
				"leadId": leadId,
				"email": email,
				"regNum": regNum,
				"phone": phone
			});
			return result.data;			
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}

	static async submitAbnConnection(email, phone, fullName, regNum) {
		try {
			const result = await axios.post(`${HOST_BE}/crm`, {
				op: 'submitAbnConnection', 
				email: email,
				phone: phone,
				fullName: fullName,
				regNum: regNum
			});

			return result.data;
		} catch (error) {
			if ((error.response.status === 422) && error.response.data) throw error.response.data; else throw error;
		}
	}
}
