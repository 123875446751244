import { Application, ApplicationStatus, ProviderPsd, StatusAml, StatusPsd } from "./application.model";
import { IPersonAttrs } from "./customer.model";

function jsonParse(json) {
	const parseDate = (key, value) => (typeof value === "string" && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d+)Z$/.test(value)) ? new Date(value) : value;
	return JSON.parse(json, parseDate);
}

export interface ApplicationDto {
	id: string;
	offerId: string;
	partnerId: string;
	partnerCode: string;
	source: string;

	status: ApplicationStatus;
	statusAml: StatusAml;
	statusAmlDate: Date;
	statusPsd: StatusPsd;
	psdAccountHolderInfoSupported: boolean;
	psdProvider: ProviderPsd;
	psdConnectionId: string;
	psdCustomerId: string;
	psdAccounts: Array<any>;
	
	account: string;
	amount: number;

	businessId: string;
	businessName: string;
	externalId: string;
	persons: Array<IPersonAttrs>;

	validTo: Date; // Offer_Valid_to
	created: Date;
	modified: Date;

	recId: string;
}

export class ApplicationMapper
{
	public static toDomain(raw: any): Application {
		if (!raw) throw new Error('InvalidParams');
		try {
			// console.log(raw);
			// console.log(raw.PSD2_AccountInfoJson)
			const name1 = raw.name_surname.split(' ');
			let application = Application.create({
				id: raw.ID,
				offerId: raw.Offer_detail ? raw.Offer_detail[0] : undefined,
				partnerId: raw.Partner_name ? raw.Partner_name[0] : undefined,
				partnerCode: raw.PartnerCode && raw.PartnerCode[0] ? raw.PartnerCode[0] : undefined,
				source: raw.Source,

				status: raw.Status,
				statusAml: raw.AML_status,
				statusAmlDate: new Date(raw.AML_checked_date),

				statusPsd: raw.Saltedge_connection_status,
				psdAccountHolderInfoSupported: raw.SaltedgeHolderInfoSupported === 'Yes' ? true : (raw.SaltedgeHolderInfoSupported === 'No' ? false : undefined),
				psdProvider: raw.PsdProvider,
				psdCustomerId: raw.Saltedge_customerId,
				psdConnectionId: raw.Saltedge_connectionId,
				psdAccounts: raw.PSD2_AccountInfoJson ? Array.from(jsonParse(raw.PSD2_AccountInfoJson)) : undefined,

				account: raw.Account_number,
				amount: raw.requested_amount,

				businessId: raw.Merchant_ICO_interest_form ? raw.Merchant_ICO_interest_form.trim() : undefined,
				businessName: raw.Company_name,
				externalId: raw.Merchant_ID,
				persons: [{
					id: raw.Person_1_ID,
					name: raw.name_surname,
					firstName: name1[0] ? name1[0] : '',
					lastName: name1[1] ? name1[1] : '',
					phone: raw.phone_1,
					email: raw.email_1,
					pep: raw.pep === 'Yes',
					statusKyc: raw.KYC_status_1
				}],

				validTo: new Date(raw.Offer_Valid_to),
				created: new Date(raw.Created),
				modified: new Date(raw.Last_modified),

				recId: raw.RecId
			});

			if (raw.signing_person === 2 || raw.signing_person === '2') {
				const name2 = raw.name_surname_2.split(' ');
				application.persons.push({
					id: raw.Person_2_ID,
					name: raw.name_surname_2,
					firstName: name2[0] ? name2[0] : '',
					lastName: name2[1] ? name2[1] : '',
					phone: raw.phone_2,
					email: raw.email_2,
					statusKyc: raw.KYC_status_2
				});
			}

			return application;
		} catch (error) {
			console.log(error)
			throw new Error('InvalidApplicationData');
		}
	}

	public static fromDto(dto: ApplicationDto): Application {
		if (!dto) throw new Error('InvalidParams');

		return Application.create({
			id: dto.id,
			offerId: dto.offerId,
			partnerId: dto.partnerId,
			partnerCode: dto.partnerCode,
			source: dto.source,

			status: dto.status,
			statusAml: dto.statusAml,
			statusAmlDate: dto.statusAmlDate,
			statusPsd: dto.statusPsd,
			psdAccountHolderInfoSupported: dto.psdAccountHolderInfoSupported,
			psdProvider: dto.psdProvider,
			psdCustomerId: dto.psdCustomerId,
			psdConnectionId: dto.psdConnectionId,
			psdAccounts: dto.psdAccounts,

			amount: dto.amount,
			account: dto.account,

			businessId: dto.businessId,
			businessName: dto.businessName,
			externalId: dto.externalId,
			persons: dto.persons,

			validTo: new Date(dto.validTo),
			created: new Date(dto.created),
			modified: new Date(dto.modified),

			recId: dto.recId
		})
	}

	public static toDto(application: Application): ApplicationDto {
		if (!application) throw new Error('InvalidParams');

		return {
			id: application.id,
			offerId: application.offerId,
			partnerId: application.partnerId,
			partnerCode: application.partnerCode,
			source: application.source,

			status: application.status,
			statusAml: application.statusAml,
			statusAmlDate: application.statusAmlDate,
			statusPsd: application.statusPsd,
			psdAccountHolderInfoSupported: application.psdAccountHolderInfoSupported,
			psdProvider: application.psdProvider,
			psdCustomerId: application.psdCustomerId,
			psdConnectionId: application.psdConnectionId,
			psdAccounts: application.psdAccounts,

			account: application.account,
			amount: application.amount,

			businessId: application.businessId,
			businessName: application.businessName,
			externalId: application.externalId,
			persons: application.persons,

			validTo: application.validTo,
			created: application.created,
			modified: application.modified,

			recId: application.recId
		}
	}
}
