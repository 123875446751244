import React from 'react'

import './product-button.css'
import {KalkProdukt} from "../../model/kalkProdukt.model";
import {ProductPeriod} from "../../model/offer.model";

/**
 * Document : fp-onboarding - product-button.tsx
 * Created on : 9.2.2022, 17:48
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

interface IButtonProduct
{
  rootClassName?: string;
  key: ProductPeriod;
  zaznamTlacitka: KalkProdukt;
  updateProduct: (selectedProdukt: KalkProdukt) => void;
}

const ProductButton = (props: IButtonProduct) =>
{
  return (
      <div className={`button-product-main ${props.rootClassName}`} onClick={props.zaznamTlacitka.active ? () => props.updateProduct(props.zaznamTlacitka) : undefined}>
        <div className={`button-product-container ${props.zaznamTlacitka.selected ? "button-product-selected" : (props.zaznamTlacitka.active ? "button-product-active" : "button-product-default")}`}>
          <label className="inter500 button-product-text" style={props.zaznamTlacitka.selected ? {color: 'white'} : (props.zaznamTlacitka.active ? {color: 'black'} : {color: 'darkgrey'})}>
            {props.zaznamTlacitka.text}
          </label>
        </div>
      </div>
  )
}

export default ProductButton
