import { useEffect, useRef, useState } from 'react';
import { Widget } from '@typeform/embed-react'
import { useNavigate, useParams } from 'react-router-dom';
import { Application } from '../model/application.model';
import { getConfig, Services } from '../model/services';
import { StatusKyc } from '../model/customer.model';
import { ApplicationProcess, ProcessStep } from '../model/application-process.model';
import { useInterval } from '../model/core';
import { LoadingComponent, loadingComponent } from './components';
import HorizontalLinearStepper from './stepper';
import axios from 'axios';
import SumsubWebSdk from '@sumsub/websdk-react'

const HOST_FE = window.location.origin;
const HOST_BE = window.location.origin + process.env.REACT_APP_FP_API;

function getKycFormId(application: Application, personId: string): string
{
	return application.persons[0].id === personId ? getConfig('REACT_APP_TYPEFORM_KYC_ID') : getConfig('REACT_APP_TYPEFORM_KYC2_ID')
}

const KycForm = (props) => {
	const initDone = useRef(false);

	const [loading, setLoading] = useState(true);
	const {personId} = useParams();
	const [isFirstPerson, setIsFirstPerson] = useState(false);
	const [sumsubToken, setSumsubToken] = useState("");
	const [formId, setFormId] = useState("");
	const [config, setConfig] = useState(null);

	const [error, setError] = useState(null);
  if (error) throw new Error(error);

	const navigate = useNavigate();

	const sumsubExpirationHandler = (): Promise<string> => { 
		return axios(`${HOST_BE}/kyc?applicationId=${props.application.id}&personId=${personId}`).then((result) => result.data.token);
	}

	// const sumsubErrorHandler = (error) => { setError(error) }

	const sumsubMessageHandler = (type, message) => { 
		console.log(type, 'type');
		console.log(message, 'message');
		console.log(message.reviewStatus, 'reviewStatus');

		if (message.reviewStatus === "completed") {
			const retry = message.reviewResult.reviewAnswer === 'RED' && message.reviewResult.reviewRejectType === 'RETRY';
			if (!retry) {
				navigate(`/application/${props.application.id}/done/success`);

				// if (isFirstPerson) {
				// 	navigate(`/application/${props.application.id}/continue`);	
				// } else {
				// 	navigate(`/application/${props.application.id}/done/success`);
				// }
			}
		}

		// if (message.reviewStatus === "completed") {
		// 	if (message.reviewResult.reviewAnswer === "RED") {
		// 		// navigate(`/application/${props.application.id}/done/kyc-failed`);
		// 		navigate(`/application/${props.application.id}/continue`);
		// 	} else {
		// 		// navigate(`/application/${props.application.id}/done/success`);
		// 		navigate(`/application/${props.application.id}/continue`);
		// 	}
		// }
	}

	useEffect(() => {
		const initApplication = async () => {
			try {
				if (!initDone.current) {

					if (!props.application || !personId) throw 'InvalidParams';
		
					const persons = props.application.persons.filter(p => p.id === personId);
					if (!persons || (persons.length === 0)) throw 'InvalidParams';

					setIsFirstPerson(props.application.persons[0].id === personId);
					const person = persons[0];

					const config = await Services.getConfig();
					setConfig(config);

					if (config['KYC_SUMSUB_ENABLED'] === 'True') {
						const result = await axios(`${HOST_BE}/kyc?op=initKycSession&applicationId=${props.application.id}&personId=${personId}`);
						console.log(result.data)
						setSumsubToken(result.data.token);
					} else {
						setFormId(getKycFormId(props.application, personId));
					}

					console.log(person);
					if (!person.statusKyc || ([StatusKyc.None, StatusKyc.InviteSent].includes(person.statusKyc))) {
						setLoading(false);
					} else {
						navigate(`/application/${props.application.id}/done/success`);
					}

					initDone.current = true;
				}
			} catch (e) {
				setError(e);
			}
		}
	
		initApplication();
		return () => {};
	}, [props.application, personId]);

	const typeformHandleOnSubmit = (data: any) => {
		// console.log(data, 'form submit')
		navigate(`/application/${props.application.id}/kyc/process/person/${personId}/response/${data.responseId}`);
	}

	if (loading) {
		return loadingComponent();
	} else if (config['KYC_SUMSUB_ENABLED'] === 'True') {
		// Dashboard customization "Flowpay"
		// - Navigation screens
		//   - Skip agreements screen
		//   - Skip review screen
		return (
			sumsubToken && <SumsubWebSdk
				accessToken={sumsubToken}
				expirationHandler={sumsubExpirationHandler}
				config={{ 
					// lang: 'cz', // !!! Intentionally commented. Setup in SumSub's Dashboarde. Inak dochadza k pridaniu jazyka "cz". 
					uiConf: { 
						// selectableLangs: ["cz", "en", "de"], // // !!! Intentionally commented. Setup in SumSub's Dashboarde. Inak dochadza k pridaniu jazyka "cz". 
						customCssStr: ".step .title {color: #ffffff} .step.active .title {color: #ffffff} :root {--gray-color: #c74fff; --red-color: #c74fff; --orange-color: #c74fff;} button.blue {background: #c74fff}",
					},
				}}
				options={{
					addViewportTag: false, adaptIframeHeight: true 
				}}
				onMessage={sumsubMessageHandler}
				onError={setError}
				className="kyc-widget"			
			/>
		)
	} else {
		return (
			<>
			{isFirstPerson ? <HorizontalLinearStepper activeStep={ProcessStep.Kyc} showPsdStep={true} showKycStep={true}/> : ''}
			{/* {isFirstPerson ? <HorizontalLinearStepper activeStep={ProcessStep.Kyc} showPsdStep={!props.process.isPsdValidPreliminary} showKycStep={!props.process.isStepCompleted(ProcessStep.Kyc)}/> : ''} */}
			<Widget 
				inlineOnMobile={true}
				enableSandbox={false} // if enableSandbox is true then the form is not submitted, i.e. no responseId is generated and no redirect fired
				onSubmit={typeformHandleOnSubmit}
				id={formId}
				// disableTracking={true}
				shareGaInstance={false}
				// disableAutoFocus={false}
				className="form"
				hidden={{
					financingrecid: props.application.recId,
					personid: personId
				}}
			/>
			</>
		);
	}
};

const KycFormProcess = (props) => {
	const initDone = useRef(false);

	const {personId, responseId} = useParams();
	const [delay, setDelay] = useState(1000);

	const [error, setError] = useState(null);
  if (error) throw new Error(error);

	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			if (!initDone.current) {
				try {
					if (!responseId || !personId || !props.application.id) throw 'InvalidParams';
				} catch (e) {
					setError(e);
				}

				initDone.current = true;
			}
		}

		init();
		
		return () => {};
  }, [props.application, responseId, personId]);

	useInterval(async () => {
		try {
			if (delay > 10000) {
				throw 'ErrorProcessingKYC';
			} else {
				const ready = await Services.checkFormDataReady(getKycFormId(props.application, personId), responseId);
				if (ready && ready.data) {
					await Services.processKycFormResponse(props.applicationId, personId, responseId);
					navigate(`/application/${props.applicationId}/done/success`);
				}
			}
		} catch (error) {
			setError(error);
		}
  }, delay);

	useInterval(() => {
		setDelay(delay + 1000);
	}, delay);

	return loadingComponent();
}

const KycFormPage = () => {
	const initDone = useRef(false);

	const {applicationId, step, personId} = useParams();
  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState<Application>();
  const [process, setProcess] = useState<ApplicationProcess>();

	const [error, setError] = useState(null);
  if (error) throw new Error(error);

	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			try {
				if (!initDone.current) {
					document.title = document.title.concat(' - KYC');

					const application: Application = await Services.getApplication(applicationId);
					setApplication(application);
					if (!application) throw 'InvalidParams';
					if (!step) throw 'InvalidParams';

					// log(application)
					try {
						const process: ApplicationProcess = await Services.getApplicationProcess(application.offerId);
						if (!process || !process.offer) throw 'InvalidParams';
						setProcess(process);
						console.log(process)

						// console.log(process.isPsdValidPreliminary, 'isPsdValidPreliminary');
						// console.log(process.isPsdValid, 'isPsdValid');

						// console.log(process.isKycValidPreliminary, 'isKycValidPreliminary');
						// console.log(process.isKycValid, 'isKycValid');
						
						if (step === "start") {
							// skip step if finished
							// do not skip if second person is opening to the form screen directly
							console.log(process)

							if (process.isStepCompleted(ProcessStep.Kyc)) {
								navigate(`/application/${applicationId}/continue`);
							}
		
							const personId = application.persons[0].id;
							const isPersonKycValid = await Services.startKycProcess(applicationId, personId);
							if (!isPersonKycValid) {
								window.location.assign(`${HOST_FE}/application/${applicationId}/kyc/form/person/${personId}`);
							} else {
								window.location.assign(`/application/${applicationId}/continue`);
								// window.location.assign(`/application/${applicationId}/done/success`);
							}
						} else {
							setLoading(false);
						}

						initDone.current = true;
					} catch (error) {
						if (error === 'Underwriting-Submitted') {
							const persons = application.persons.filter(p => p.id === personId);
							if (!persons || (persons.length === 0)) throw 'InvalidParams';
							if ((application.persons.length === 1) || (application.persons[1].id !== personId)) {						
								throw error;
							} else {
								setLoading(false);
								initDone.current = true;
							}
						}
					}
				}
			} catch (e) {
				setError(e);
			}
		}
		init();
		
		return () => {};
  }, [applicationId, step]);

	if (loading) {
		return (
			<>
			<LoadingComponent/>
			</>
		)
	} else if (step === "form") {
		return (
			<>
			<KycForm applicationId={applicationId} application={application} process={process}/>
			</>
		)
	} else if (step === "process") {
		return (
			<>
			<KycFormProcess applicationId={applicationId} application={application} process={process}/>
			</>
		)
	}	else if (step === "completed") {

	}
};

export default KycFormPage;
