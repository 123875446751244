import { ApplicationDto, ApplicationMapper } from "./application.mapper";
import { ApplicationProcess } from "./application-process.model";
import { OfferDto, OfferMapper } from "./offer.mapper";

export interface ApplicationProcessDto {
	offer: OfferDto;
	application: ApplicationDto;
	isPsdValid: boolean;
	isKycValid: boolean;
	isAmlValid: boolean;
	isPsdValidPreliminary: boolean;
	isKycValidPreliminary: boolean;
	isUnderwriting: boolean;
	psdValidAppId: string;
	kycValidAppId: string;
}

export class ApplicationProcessMapper
{
	public static fromDto(dto: ApplicationProcessDto): ApplicationProcess {
		if (!dto) throw new Error('InvalidParams');

		return ApplicationProcess.create({
			offer: OfferMapper.fromDto(dto.offer),
			application: dto.application ? ApplicationMapper.fromDto(dto.application) : undefined,
			isPsdValid: dto.isPsdValid,
			isAmlValid: dto.isAmlValid,
			isKycValid: dto.isKycValid,
			isPsdValidPreliminary: dto.isPsdValidPreliminary,
			isKycValidPreliminary: dto.isKycValidPreliminary,
			isUnderwriting: dto.isUnderwriting,
			psdValidAppId: dto.psdValidAppId,
			kycValidAppId: dto.kycValidAppId
		});
	}

	public static toDto(bundle: ApplicationProcess): ApplicationProcessDto {
		if (!bundle) throw new Error('InvalidParams');

		return {
			offer: OfferMapper.toDto(bundle.offer),
			application: bundle.application ? ApplicationMapper.toDto(bundle.application) : undefined,
			isPsdValid: bundle.isPsdValid,
			isAmlValid: bundle.isAmlValid,
			isKycValid: bundle.isKycValid,
			isPsdValidPreliminary: bundle.isPsdValidPreliminary,
			isKycValidPreliminary: bundle.isKycValidPreliminary,
			isUnderwriting: bundle.isUnderwriting,
			psdValidAppId: bundle.psdValidAppId,
			kycValidAppId: bundle.kycValidAppId
		}
	}
}
