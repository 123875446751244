import moment from 'moment';
import { EntityValidator } from './entity';

export enum LeadStage {
	Lead = 'Lead',
	Prospect = 'Prospect',
	Opportunity = 'Opportunity',
	Deal = 'Deal',
	Successful = 'Successful',
	Interest = 'Interest'
}

// deprecated
export enum ProductPeriod {
  W1 = '1_week',
  W2 = '2_week',
  M1 = '1_month',
  M3 = '3_month',
  M6 = '6_month',
  M12 = '12_month',
}

export enum LegacyProductMap {
  W1 = '1_week',
  W2 = '2_week',
  M1 = '1_month',
  M3 = '3_month',
  M6 = '6_month',
  M12 = '12_month',
}

export enum LegacyProductMapReverse {
  '1_week' = 'W1',
  '2_week' = 'W2',
  '1_month' = 'M1',
  '3_month' = 'M3',
  '6_month' = 'M6',
  '12_month' = 'M12',
}

export enum Product {
  W1 = 'W1',
  W2 = 'W2',
  M1 = 'M1',
  M3 = 'M3',
  M6 = 'M6',
  M12 = 'M12',
}

export enum InstallmentPeriod {
	Week = 'Week',
	Month = 'Month'
}

export const ProductCatalogue = {
	// W1: {
	// 	id: Product.W1,
	// 	installmentPeriod: InstallmentPeriod.Week,
	// 	installmentPeriodCount: 1,
	//   interestRateMultiplier: 0.25,
	//   installmentCount: 1,
	// 	installmentCountCF: 1,
	// 	postponeEnabled: false,
	// 	postponeFeeMultiplier: 1,
	// 	postponeFeeMultiplierCF: 1,
	// 	prolongationEnabled: false,
	// 	prolongationFeeMultiplier: 1,
	// },
  // W2: {
	// 	id: Product.W2,
	// 	installmentPeriod: InstallmentPeriod.Week,
	// 	installmentPeriodCount: 2,
	//   interestRateMultiplier: 0.5,
	//   installmentCount: 1,
	// 	installmentCountCF: 1,
	// 	postponeEnabled: false,
	// 	postponeFeeMultiplier: 1,
	// 	postponeFeeMultiplierCF: 1,
	// 	prolongationEnabled: false,
	// 	prolongationFeeMultiplier: 1,
	// },
  M1: {
		id: Product.M1,
		baloon: true,
		installmentPeriod: InstallmentPeriod.Month,
		// installmentPeriodCount: 1,
		interestRateMultiplier: 1,
		installmentCount: 1,
		// installmentCountCF: 2,
		postponeEnabled: false,
		// postponeFeeMultiplier: 2,
		// postponeFeeMultiplierCF: 1,
		prolongationEnabled: true,
		prolongationFeeMultiplier: 1,
		prolongationFeeMultiplierCF: 0,
	},
  M3: {
		id: Product.M3,
		baloon: true,
		installmentPeriod: InstallmentPeriod.Month,
		// installmentPeriodCount: 3,
		interestRateMultiplier: 1,
		installmentCount: 3,
		// installmentCountCF: 4,
		postponeEnabled: false,
		// postponeFeeMultiplier: 2,
		// postponeFeeMultiplierCF: 1,
		prolongationEnabled: true,
		prolongationFeeMultiplier: 1,
		prolongationFeeMultiplierCF: 0,
	},
  M6: {
		id: Product.M6,
		baloon: false,
		installmentPeriod: InstallmentPeriod.Month,
		// installmentPeriodCount: 6,
		interestRateMultiplier: 1,
		installmentCount: 6,
		// installmentCountCF: 6,
		postponeEnabled: true,
		postponeFeeMultiplier: 1,
		postponeFeeMultiplierCF: 0,
		prolongationEnabled: false,
		// prolongationFeeMultiplier: 1,
	},
  M12: {
		id: Product.M12,
		baloon: false,
		installmentPeriod: InstallmentPeriod.Month,
		// installmentPeriodCount: 12,
		interestRateMultiplier: 1,
		installmentCount: 12,
		// installmentCountCF: 12,
		postponeEnabled: true,
		postponeFeeMultiplier: 1,
		postponeFeeMultiplierCF: 0,
		prolongationEnabled: false,
		// prolongationFeeMultiplier: 1,
	}
};

export enum OfferStatus {
  New = 'New',
	Lead = 'Lead',
  Sent = 'Offer sent',
	Expired = 'Expired',
	Successful = 'Successful'
}

export interface IInstallment {
	index: number;
	principal: number;
	fee: number;
	dueDate: string; // YYYY-MM-DD
}

export interface IInstallmentPlan {
	product: string;
	productId: Product;
	amount: number;
	postponeLength: number;
	prolongationLength: number;
	feeMonthly: number;
	amountComplete: number;
	feePostponeMonth: number;
	feePostponeTotal: number;
	feePostpone: number;
	feeProlongation: number;
	feeProlongationTotal: number;
	crowdfunding: boolean;
	prolongation: boolean;
	baloon: boolean;
	amountCompleteBrutto?: number;
	installments: Array<IInstallment>;
}


export interface IOfferProduct {	
	id: ProductPeriod, 
	period: ProductPeriod,
	interestRate: number,
	maxLoanAmount: number,
	fee?: number,
}

export interface IOfferAttrs {
	id: string;
	partnerRecId: string;
	partnerId: string;
	externalId: string;
	externalId2: string;
	businessId: string;
	businessName: string;
	products: Array<IOfferProduct>;
	status: OfferStatus;
	expired: Date;
	sent: Date;
	created: Date;
	modified: Date;
	Interest_rate_offer_1week?: number;
	Interest_rate_offer_2week?: number;
	Interest_rate_offer_1month?: number;
	Interest_rate_offer_3month?: number;
	Interest_rate_offer_6month?: number;
	Interest_rate_offer_12month?: number;
	max_loan_amount_1week?: number;
	max_loan_amount_2week?: number;
	max_loan_amount_1month?: number;
	max_loan_amount_3months?: number;
	max_loan_amount_6months?: number;
	max_loan_amount_12months?: number;
	leadRecId?: string;
	Crowdfunding_offer?: string;
}

export class Offer {
	private attrs: IOfferAttrs;

	get id(): string {
		return this.attrs.id;
	}

	get partnerId(): string {
		return this.attrs.partnerId;
	}

	get partnerRecId(): string {
		return this.attrs.partnerRecId;
	}

	get externalId(): string {
		return this.attrs.externalId;
	}

	get externalId2(): string {
		return this.attrs.externalId2;
	}

	set businessId(businessId: string) {
		this.attrs.businessId = businessId;
	}

	get businessId(): string {
		return this.attrs.businessId;
	}

	get businessName(): string {
		return this.attrs.businessName;
	}

	get status(): OfferStatus {
		return this.attrs.status;
	}

	get products(): Array<IOfferProduct> {
		return this.attrs.products;
	}

	get expired(): Date {
		return this.attrs.expired;
	}

	get sent(): Date {
		return this.attrs.sent;
	}

	get created(): Date {
		return this.attrs.created;
	}

	get modified(): Date {
		return this.attrs.modified;
	}

	get Interest_rate_offer_1week(): number
	{
		return this.attrs.Interest_rate_offer_1week;
	}

	get Interest_rate_offer_2week(): number
	{
		return this.attrs.Interest_rate_offer_2week;
	}

	get Interest_rate_offer_1month(): number
	{
		return this.attrs.Interest_rate_offer_1month;
	}

	get Interest_rate_offer_3month(): number
	{
		return this.attrs.Interest_rate_offer_3month;
	}

	get Interest_rate_offer_6month(): number
	{
		return this.attrs.Interest_rate_offer_6month;
	}

	get Interest_rate_offer_12month(): number
	{
		return this.attrs.Interest_rate_offer_12month;
	}

	get max_loan_amount_1week(): number
	{
		return this.attrs.max_loan_amount_1week;
	}

	get max_loan_amount_2week(): number
	{
		return this.attrs.max_loan_amount_2week;
	}

	get max_loan_amount_1month(): number
	{
		return this.attrs.max_loan_amount_1month;
	}

	get max_loan_amount_3months(): number
	{
		return this.attrs.max_loan_amount_3months;
	}

	get max_loan_amount_6months(): number
	{
		return this.attrs.max_loan_amount_6months;
	}

	get max_loan_amount_12months(): number
	{
		return this.attrs.max_loan_amount_12months;
	}

	get leadRecId(): string
	{
		return this.attrs.leadRecId;
	}

	get Crowdfunding_offer(): string
	{
		return this.attrs.Crowdfunding_offer;
	}

	private constructor(attrs: IOfferAttrs)
	{
		this.attrs = attrs;
	}

	public static create(attrs: IOfferAttrs): Offer
	{
		// EntityValidator.Mandatory('partnerId', attrs.partnerId);
		// EntityValidator.Mandatory('partnerId', attrs.status);

		return new Offer(attrs);
	}

	public calculateInstallmentPlan(legacyProductId: ProductPeriod, loanAmount: number, postponeLength: number, fromDate: string, crowdfunding: boolean = false, prolongation: boolean = false, fee?: number): IInstallmentPlan
	{
		const round2 = (num) => {
			var m = Number((Math.abs(num) * 100).toPrecision(15));
			return Math.round(m) / 100 * Math.sign(num);
		}
		
		// console.log('calculateInstallmentPlan')

		const productDef = ProductCatalogue[LegacyProductMapReverse[legacyProductId]];
		const offeredProduct = this.products.filter(p => p.id === legacyProductId)[0];

		// console.log(postponeLength, 'postpone')
		// console.log(productDef.id, 'productDef.id')
		// if (postponeLength > 0 && ![Product.M3, Product.M6, Product.M12].includes(productDef.id)) {
		// 	// throw new Error('InvalidParams')
		// 	postponeLength = 0;
		// }

		// if (productDef.id === Product.M3) {
		// 	postponeLength = 2;
		// }

    let celkemSplatky = 0;
    let celkemKomplet = 0;

		let firstDate = moment.utc(fromDate);
		firstDate.add(3, 'days');

		if (!productDef.postponeEnabled) {
			postponeLength = 0;
		}

		let prolongationLength = productDef.prolongationEnabled && (prolongation || crowdfunding) ? 1 : 0;

		const installmentCount = productDef.installmentCount + prolongationLength;
		const installmentAmount = Math.ceil(loanAmount / (productDef.installmentCount - postponeLength));
		// if (legacyProductId === ProductPeriod.M6 && postponeLength === 1 && crowdfunding === false) {
		// 	console.log(loanAmount)
		// 	console.log(installmentCount, productDef.installmentCount, prolongationLength)
		// 	console.log(installmentAmount)
		// }
		// const feeMonthly = fee === undefined ? Math.ceil(((offeredProduct.interestRate / 100) * loanAmount) * productDef.interestRateMultiplier) : fee;
		// console.log(offeredProduct.interestRate, fee)
		// console.log(productDef.interestRateMultiplier)
		const feeMonthly = fee === undefined ? 
			Math.ceil(round2(round2((offeredProduct.interestRate / 100) * loanAmount) * productDef.interestRateMultiplier)) : 
			Math.ceil(round2(round2((fee / 100) * loanAmount) * productDef.interestRateMultiplier));

		const postponeFeeMultiplier = postponeLength > 0 ? (crowdfunding ? productDef.postponeFeeMultiplierCF : productDef.postponeFeeMultiplier) : 1;
		const postponeFee = postponeLength > 0 ? postponeFeeMultiplier * feeMonthly : 0;

		const prolongationFeeMultiplier = prolongationLength > 0 ? (crowdfunding ? productDef.prolongationFeeMultiplierCF : productDef.prolongationFeeMultiplier) : 1;
		const prolongationFee = prolongationLength > 0 ? prolongationFeeMultiplier * feeMonthly : 0;

		let nextDate;
		let listSplatek = [];
    for (let i = 0; i < installmentCount; i++) {
      if (productDef.installmentPeriod === InstallmentPeriod.Month) {
      	nextDate = moment.utc(firstDate);
				nextDate.add(i + 1, "month");
      } else {
        nextDate.add(7 * productDef.installmentPeriodCount, 'days');
      }

			const isLast = i + 1 === installmentCount;
			const isPostponed = postponeLength > 0 && i < postponeLength;
			const isProlonged = prolongationLength > 0 && i < prolongationLength;
			const amount = isPostponed || isProlonged || (productDef.baloon && !isLast) ? 0 : (isLast ? loanAmount - celkemSplatky : installmentAmount);
			// const fee = isProlonged ? prolongationFee : feeMonthly + (isPostponed ? postponeFee : 0);
			const fee = feeMonthly + (isPostponed ? postponeFee : 0) + (isProlonged ? prolongationFee : 0);

			celkemSplatky = celkemSplatky + amount;
      celkemKomplet = celkemKomplet + amount + fee;

			listSplatek.push({
				index: i + 1,
				principal: amount,
				fee: fee,
				dueDate: nextDate.toISOString().slice(0, 10),
			})
		}
		
		const postponeFeeDef = productDef.postponeEnabled && postponeLength > 0 ? productDef.postponeFeeMultiplier * feeMonthly * postponeLength : 0;
		const prolongationFeeDef = productDef.prolongationEnabled ? productDef.prolongationFeeMultiplier * feeMonthly : 0;

		return {
			product: LegacyProductMap[productDef.id],
			productId: productDef.id,
			baloon: productDef.baloon,
			crowdfunding: crowdfunding,

			amount: loanAmount,
			feeMonthly: feeMonthly,
			amountComplete: celkemKomplet,
			// amountCompleteBrutto: celkemKomplet + (productDef.postponeEnabled && postponeLength > 0 ? postponeFeeDef : 0) + (prolongationLength > 0 ? prolongationFeeDef - feeMonthly : 0),
			amountCompleteBrutto: celkemKomplet + (crowdfunding ? postponeFeeDef + prolongationFeeDef : 0),

			postponeLength: postponeLength,
			feePostponeMonth: productDef.postponeEnabled && postponeLength > 0 ? postponeFeeMultiplier * feeMonthly : 0,
			feePostponeTotal: productDef.postponeEnabled && postponeLength > 0 ? postponeFeeMultiplier * feeMonthly * postponeLength : 0,
			feePostpone: postponeFeeDef,

			prolongation: prolongation,
			prolongationLength: prolongationLength,
			feeProlongation: prolongationFeeDef, 
			feeProlongationTotal: prolongationFee,

			installments: listSplatek
		};
	}	
}
