import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";

export function subtractYears(date: Date, numYears: number) {
	return new Date(date.setFullYear(date.getFullYear() - numYears));
}

export function useInterval(callback, delay) {
  const savedCallback: any = useRef();

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    function tick() {
			savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [callback, delay]);
}

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Formátování data CZ
 * @param value
 */
export function formatDate(value: Date, year: boolean = true): string
{
  const sDate = `${value.getDate()}.${value.getMonth() + 1}.`
  if (!year)
  {
    return sDate
  }
  return `${sDate}${value.getFullYear()}`;
}

/**
 * Formátování částky CZ
 * @param value
 */
export function formatAmount(value: number): string
{
  // Regex pro mezery mezi čísly
  const numSpacesRegex = /\B(?=(\d{3})+(?!\d))/g;
  return value.toString().replace(numSpacesRegex, " ");
}

/**
 * Interface pro dimenze okna
 */
export interface IDimensions
{
  width: number,
  height: number,
}

/**
 * Hook dimenzí okna
 */
export const useWindowSize = () : IDimensions =>
{
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<IDimensions>({
    width: undefined,
    height: undefined,
  });

  useEffect(() =>
  {
    // Handler to call on window resize
    function handleResize()
    {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

/**
 * Useknutí desetinných čísel
 * @param value
 * @param precision
 */
export const round = (value: number, precision: number = 0): number =>
{
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}