import axios from "axios";
import { AlertColor } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getLoginData } from "../auth";
import { ApplicationProcess } from "../model/application-process.model";
import { Application } from "../model/application.model";
import { getConfig, Services } from "../model/services";
import { LoadingComponent, loadingComponent } from "./components";
import { useAuth0 } from '@auth0/auth0-react';

const DonePage = () => {
	const initDone = useRef(false);
	const {status, applicationId} = useParams();

	const [error, setError] = useState(null);
  if (error) throw new Error(error);
	
	let severity: AlertColor = "error";
	let message: string = status;

	const navigate = useNavigate();

	const { buildAuthorizeUrl } = useAuth0();


	useEffect(() => {
		const init = async () => {
			try {
				if (!initDone.current) {

					if (status === 'success') {
						document.title = document.title.concat(' - Success');

						// if (applicationId) {
						// 	const application: Application = await Services.getApplication(applicationId);
						// 	if (!application) throw 'InvalidParams';

						// 	const { loginData, headers } = await getLoginData(
						// 		buildAuthorizeUrl,
						// 		application.persons[0].email
						// 	);
					
						// 	const response = await axios.post('https://' + process.env.REACT_APP_AUTH0_DOMAIN + '/passwordless/start', loginData, headers);
						// }
					}

					// const process: ApplicationProcess = await Services.getApplicationProcess(application.offerId);
					// if (!process) throw 'InvalidParams';
					// console.log(process)

					// if (process.isCompleted) {
					// 	await Services.finishApplication(application.offerId, applicationId);
					// }
	
					// navigate(`/application/${applicationId}/done/success`);

					initDone.current = true;
				}
			} catch (e) {
				setError(e);
			}
		}
		init();
		
		return () => {};
  }, [status, applicationId, buildAuthorizeUrl]);

	if (status === 'finish') {
		return (
			<LoadingComponent/>
		);
	} else if (status === 'success') {
		return (

			<div className="content">
				<h1>Děkujeme za Váš projevený zájem</h1>
				Je to na dobré cestě! Brzy se vám ozveme s dalšími kroky.
				{/* Kliknutím na odkaz se přihlásíte do myFlowpay. Brzy se vám ozveme s dalšími kroky. */}
			</div>

		);
	} else {
		return (
			<>
				<div className="content">
					{message}
				</div>
			</>
		);
	}
};

export default DonePage;
