import React from 'react'

import './bottom-button.css'

/**
 * Document : fp-onboarding - bottom-button.tsx
 * Created on : 9.2.2022, 17:47
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

export enum TypPotvrdButton
{
  MAM_ZAJEM, ZVYHODNI
}

interface IPotvrdButton
{
  rootClassName?: string;
  style?: {};
  onClick: () => void;
  text: string;
  inverse: boolean;
}

const BottomButton = (props: IPotvrdButton) =>
{
  let containerClass = "";
  let textClass = "";
  if (!props.inverse)
  {
    containerClass = "bottom-button-container-normal";
    textClass = "bottom-button-text-normal";
  }
  else
  {
    containerClass = "bottom-button-container-inverse";
    textClass = "bottom-button-text-inverse";
  }

  return (
      <div style={props.style} onClick={() => props.onClick()} className={`bottom-button-container ${props.rootClassName} ${containerClass}`}>
        <label className={`inter700 bottom-button-text ${textClass}`}>{props.text}</label>
      </div>
  )
}

export default BottomButton
