import React from 'react'

import './info-button-big.css'
import {formatAmount} from "../../model/core";

/**
 * Document : fp-onboarding - info-button-big.tsx
 * Created on : 9.2.2022, 17:49
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

interface IInfoButtonBig
{
  rootClassName?: string;
  crowdfunded?: boolean;
  puvodniCastka?: number;
  castka: number;
  info: string;
  inverted: boolean;
}

const InfoButtonBig = (props: IInfoButtonBig) =>
{
  return (
      <div style={props.inverted ? {background: '#FF4F90'} : {background: 'white'}} className={`info-button-layout-big ${props.rootClassName}`}>
        <label style={props.inverted ? {color: 'white'} : {color: 'dimgrey'}} className="inter700 info-button-info-big">
          {props.info}
        </label>
        <label style={props.inverted ? {color: 'white'} : {color: '#FF4F90'}} className="inter800 info-button-castka-big">
          {formatAmount(props.castka)} Kč
        </label>

        {props.crowdfunded &&
            <label className="inter600 info-button-castka-big-puvodni">
              {props.puvodniCastka && formatAmount(props.puvodniCastka)} Kč
            </label>}
      </div>
  )
}

export default InfoButtonBig
